import React from 'react';
import Loadable from 'react-loadable';

const appLoader = {
    returnLoaderFunc,
    returnTableLoaderFunc
};

function returnLoaderFunc() {
    return <div className="app-loader show-loader">
        <div className="loader-logo"></div>
    </div>
};

function returnTableLoaderFunc() {
    return <table className="table table-loader"></table>
};

export default appLoader;

export const App = Loadable({
    loader: () => import('./../App'),
    loading: () => appLoader.returnLoaderFunc()
});

export const LandingPage = Loadable({
    loader: () => import('./AppModule/LandingPage/LandingPage'),
    loading: () => appLoader.returnLoaderFunc()
});

export const PageNotFoundComponent = Loadable({
    loader: () => import('./AppModule/PageNotFound/PageNotFoundComponent'),
    loading: () => appLoader.returnLoaderFunc()
});

export const Login = Loadable({
    loader: () => import('./AppModule/Login/Login'),
    loading: () => appLoader.returnLoaderFunc()
});

export const LeadManagement = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadManagementComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const LeadDashboard = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadDashboard/LeadDashboardComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const AccountingManagement = Loadable({
    loader: () => import('./FeatureModule/Accounting/AccountManagementComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const SettingManagement = Loadable({
    loader: () => import('./FeatureModule/Settings/SettingsManagementComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const SettingDashboard = Loadable({
    loader: () => import('./FeatureModule/Settings/ProductSettings/PSDashboardComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const PDDashboard = Loadable({
    loader: () => import('./FeatureModule/Settings/ProductSettings/Cards/MembershipCardsComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const QuickSaleDashboard = Loadable({
    loader: () => import('./FeatureModule/Settings/ProductSettings/POS/PosComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const AccountingDashboard = Loadable({
    loader: () => import('./FeatureModule/Accounting/AccDashboard/AccountingDashboardComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const AllEarning = Loadable({
    loader: () => import('./FeatureModule/Accounting/AccDashboard/AccEarningComponent/AccEarningComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const AccountingBalance = Loadable({
    loader: () => import('./FeatureModule/Accounting/AccDashboard/AccBalanceComponent/AccBalanceComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const AccountingExpense = Loadable({
    loader: () => import('./FeatureModule/Accounting/AccDashboard/AccExpenseComponent/AccExpenseComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const AllLeads = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadDashboard/AllLeadsComponent/AllLeadsComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const NewLeads = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadDashboard/NewLeadsComponent/NewLeadsComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const FollowUps = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadDashboard/FollowUpComponent/FollowUpComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const PendingLeads = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadDashboard/PendingLeadsComponent/PendingLeadsComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const CampaignsComponent = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Campaigns/CampaignsComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const Email = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Campaigns/Email/Email'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const WhatsApp = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Campaigns/WhatsApp/WhatsApp'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const SMS = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Campaigns/SMS/SMS'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const Mobile = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Campaigns/Mobile/Mobile'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const StatisticsComponent = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Statistics/StatisticsComponent'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const StatsOverview = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Statistics/StatsOverview/StatsOverview'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const StatsDistribution = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Statistics/StatsDistribution/StatsDistribution'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const TagTrends = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/Statistics/TagTrends/TagTrends'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const PostPayment = Loadable({
    loader: () => import('./FeatureModule/SharedComponents/PostPayment/PostPayment'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const PaymentResponse = Loadable({
    loader: () => import('./FeatureModule/SharedComponents/PostPayment/PaymentResponse/PaymentResponse'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MemberManagement = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberManagement'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MemberDashboard = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberDashboard/MemberDashboard'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const AllMembers = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberDashboard/AllMembers/AllMembers'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const ActiveMembers = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberDashboard/ActiveMembers/ActiveMembers'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const ExpiredMembers = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberDashboard/ExpiredMembers/ExpiredMembers'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MembersWithSubscription = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberDashboard/MembersWithSubscriptions/MembersWithSubscriptions'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MembersWithoutSubscription = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberDashboard/MembersWithoutSubscriptions/MembersWithoutSubscriptions'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const SalesInsights = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/SalesInsights/SalesInsights'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const Products = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/Products/Products'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const ActiveProducts = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/Products/ActiveProducts/ActiveProducts'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const InactiveProducts = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/Products/InactiveProducts/InactiveProducts'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MemberCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberCampaignHistory/MemberCampaignHistory'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MemberEmailCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberCampaignHistory/Email/Email'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MemberSMSCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberCampaignHistory/SMS/SMS'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const MemberWhatsAppCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/MemberManagement/MemberCampaignHistory/WhatsApp/WhatsApp'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const LeadCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadCampaignHistory/LeadCampaignHistory'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const LeadEmailCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadCampaignHistory/Email/Email'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const LeadSMSCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadCampaignHistory/SMS/SMS'),
    loading: () => appLoader.returnTableLoaderFunc()
});

export const LeadWhatsAppCampaignHistory = Loadable({
    loader: () => import('./FeatureModule/LeadManagement/LeadCampaignHistory/WhatsApp/WhatsApp'),
    loading: () => appLoader.returnTableLoaderFunc()
});
