const initialState = {
    type:'CLEAR_STATUS',
    isClearStatus: false
};

const selectLeadStatus = (state = initialState, action) => {
    return {
        ...state,
        isClearStatus:action.isClearStatus
    }
};

export default selectLeadStatus;