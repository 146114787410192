
export const ADD_MEMBER_TO_ALL_MEMBERS = 'ADD_MEMBER_TO_ALL_MEMBERS';
export const ADD_MEMBER_TO_ACTIVE_MEMBERS = 'ADD_MEMBER_TO_ACTIVE_MEMBERS';
export const ADD_MEMBER_TO_EXPIRED_MEMBERS = 'ADD_MEMBER_TO_EXPIRED_MEMBERS';
export const ADD_MEMBER_WITH_SUBSCRIPTION = 'ADD_MEMBER_WITH_SUBSCRIPTION';
export const ADD_MEMBER_WITHOUT_SUBSCRIPTION = 'ADD_MEMBER_WITHOUT_SUBSCRIPTION';


export const DELETE_MEMBER_FROM_ALL_MEMBERS = 'DELETE_MEMBER_FROM_ALL_MEMBERS';
export const DELETE_MEMBER_FROM_ACTIVE_MEMBERS = 'DELETE_MEMBER_FROM_ACTIVE_MEMBERS';
export const DELETE_MEMBER_FROM_EXPIRED_MEMBERS = 'DELETE_MEMBER_FROM_EXPIRED_MEMBERS';
export const DELETE_MEMBER_WITH_SUBSCRIPTION = 'DELETE_MEMBER_WITH_SUBSCRIPTION';
export const DELETE_MEMBER_WITHOUT_SUBSCRIPTION = 'DELETE_MEMBER_WITHOUT_SUBSCRIPTION';

export const CHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE = 'CHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE';
export const UNCHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE = 'UNCHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE';

export const CHECK_SELECT_ALL_MEMBERS = 'CHECK_SELECT_ALL_MEMBERS';
export const UNCHECK_SELECT_ALL_MEMBERS = 'UNCHECK_SELECT_ALL_MEMBERS';

export const REMOVE_ALL_SELECTED_MEMBERS = 'REMOVE_ALL_SELECTED_MEMBERS';


export const addMemberToAllMembers = (selectedMember) => (
    {type: ADD_MEMBER_TO_ALL_MEMBERS, selectedMember: selectedMember}
);

export const addMemberToActiveMembers = (selectedMember) => (
    {type: ADD_MEMBER_TO_ACTIVE_MEMBERS, selectedMember: selectedMember}
);

export const addMemberToExpiredMembers = (selectedMember) => (
    {type: ADD_MEMBER_TO_EXPIRED_MEMBERS, selectedMember: selectedMember}
);

export const addMemberWithSubscription = (selectedMember) => (
    {type: ADD_MEMBER_WITH_SUBSCRIPTION, selectedMember: selectedMember}
);

export const addMemberWithoutSubscription = (selectedMember) => (
    {type: ADD_MEMBER_WITHOUT_SUBSCRIPTION, selectedMember: selectedMember}
);

export const deleteMemberFromAllMembers = (selectedMember) => (
    {type: DELETE_MEMBER_FROM_ALL_MEMBERS, selectedMember: selectedMember}
);

export const deleteMemberFromActiveMembers = (selectedMember) => (
    {type: DELETE_MEMBER_FROM_ACTIVE_MEMBERS, selectedMember: selectedMember}
);

export const deleteMemberFromExpiredMembers = (selectedMember) => (
    {type: DELETE_MEMBER_FROM_EXPIRED_MEMBERS, selectedMember: selectedMember}
);

export const deleteMemberWithSubscription = (selectedMember) => (
    {type: DELETE_MEMBER_WITH_SUBSCRIPTION, selectedMember: selectedMember}
);

export const deleteMemberWithoutSubscription = (selectedMember) => (
    {type: DELETE_MEMBER_WITHOUT_SUBSCRIPTION, selectedMember: selectedMember}
);

export const checkCurrentPageSelectAll = () => (
    {type: CHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE}
);

export const unCheckCurrentPageSelectAll = () => (
    {type: UNCHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE}
);

export const checkSelectAllMembers = (data) => (
    {type: CHECK_SELECT_ALL_MEMBERS, currentURL : data.currentURL, currentTab: data.currentTab}
);

export const uncheckSelectAllMembers = () => (
    {type: UNCHECK_SELECT_ALL_MEMBERS}
);

export const removeAllSelectedMembers = () => (
    {type: REMOVE_ALL_SELECTED_MEMBERS}
);

