import React , {Component} from 'react';
import { connect } from 'react-redux';
import './ToasterNotifcation.scss';
const timeout = 5000;

class ToasterNotification extends Component{

    constructor(props) {
        super(props);
        this.state = {
            notifications:[]
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const notification = { data: nextProps.data, timeStamp: new Date().getTime()};
        if(notification && notification.data && notification.data.responseType){
            this.setState({notifications: this.state.notifications.concat(notification)},
                ()=> this.removeNotification(notification)
            );
        }
    }

    removeNotification(notiFn){
        setTimeout( () => {
            this.setState({notifications: this.state.notifications.filter(
                    notification => notification.timeStamp !== notiFn.timeStamp
                )})
        }  ,timeout);
    }

    handleCancelClick = (indexR) =>{
        let notifications = this.state.notifications.filter(
            (_n,index) => index !== indexR
        );
        this.setState({notifications: notifications });
    };

    render() {
        return (
                <div>
                    <ul className="toaster-list">
                        {
                            this.state.notifications.length > 0 ?
                                this.state.notifications.map( (notification,index) =>
                                    <li key={index}>
                                        <div className="toaster-notification" key={notification.timeStamp}>
                                            <div className={notification.data.responseType}>
                                                <span className="toaster-icon" onClick={()=> this.handleCancelClick(index)}></span>
                                                { notification.data.message }
                                            </div>
                                        </div>
                                    </li>
                                ) : null
                        }
                    </ul>
                </div>
        );

    }
}


const mapStateToProps = state => ({
    data: state.notification
});

export default connect(mapStateToProps)(ToasterNotification);
