const initialState = {
    type: 'TAB_LEADS_COUNT',
    allLeadsCount : 0,
    newLeadsCount : 0,
    todaysFollowUpLeadsCount : 0,
    pendingFollowUpLeadsCount : 0,
};

const tabsLeadCount = (state = initialState, action) => {
    switch (action.type) {
        case 'TAB_LEADS_COUNT' :
            return action;
        default :
            return state;
    }
};

export default tabsLeadCount;