import React, { Component} from "react";
import "babel-polyfill"
import "./App.scss";
import {BrowserRouter} from "react-router-dom";
import ToasterNotification from './Modules/AppModule/ToasterNotification/ToasterNotification';
import AppLoader from "./Modules/AppModule/AppLoader/AppLoader";
import RechargeModal from "./Modules/FeatureModule/SharedComponents/RechargeModal/RechargeModal";

class App extends Component{

    render(){
    return(
        <BrowserRouter>
        <div>
            <div className="main-app">
                <div className="row no-gutters">
                    {this.props.children}
                </div>

                <RechargeModal />

                <div className="col-md-12 text-center footer">
                    <ToasterNotification />
                </div>
            </div>
           <AppLoader/>
        </div>
        </BrowserRouter>
    );
  }
}

export default App; 
