const initialState = {
    type: 'PAGINATION_DATA',
    pagination : null
};

const paginationData = (state = initialState, action) => {
    switch (action.type) {
        case 'PAGINATION_DATA' : return action;
        default : return state
    }
};

export default paginationData;