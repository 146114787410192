const initialState = {
    type: 'LEADS_TAG_TRENDS',
    currentFilter : 'Last 28 Days'
};

const setLeadsTagTrendsFilter = (state = initialState, action) => {
    switch (action.type) {
        case 'LEADS_TAG_TRENDS' : return action;
        default : return state;
    }
};

export default setLeadsTagTrendsFilter;