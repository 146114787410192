const initialState = {
    type: 'NEW_LEADS',
    currentURL : null
};

const newLeadsPaginationDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'NEW_LEADS' :
            return action;
        default :
            return state;
    }
};

export default newLeadsPaginationDetails;