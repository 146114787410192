import Constants from '../../Constants/Constants';
import * as actions from '../Actions/leadListActions';
import {CHECK_SELECT_ALL_LEADS} from "../Actions/leadListActions";

const initialState = {
    allLeads: {},
    newLeads: {},
    todaysFollowUps: {},
    pendingFollowUps: {},
    currentPageSelectAll: false,
    selectAllLeads: { isSelectAll: false, currentURL : '', currentTab :''}
};

const selectedLeads = (state = initialState, action) => {
    switch (action.type) {

        case  actions.ADD_LEAD_TO_ALL_LEADS :
            return {
                ...state,
                allLeads:{
                    ...state.allLeads,
                    [action.selectedLead.id]: action.selectedLead
                }
            };
        case  actions.DELETE_LEAD_FROM_ALL_LEADS :
            return  {
                ...state,
                allLeads: {
                    ...state.allLeads,
                    [action.selectedLead.id]: null
                }
            };

        case  actions.ADD_LEAD_TO_NEW_LEADS :
            return {
                ...state,
                newLeads: {
                    ...state.newLeads,
                    [action.selectedLead.id]: action.selectedLead
                }
            };
        case  actions.DELETE_LEAD_FROM_NEW_LEADS :
            return  {
                ...state,
                newLeads: {
                    ...state.newLeads,
                    [action.selectedLead.id]: null
                }
            };


        case  actions.ADD_LEAD_TO_PENDING_FOLLOW_UPS :
            return {
                ...state,
                pendingFollowUps: {
                    ...state.pendingFollowUps,
                    [action.selectedLead.id]: action.selectedLead
                }
            };
        case  actions.DELETE_LEAD_FROM_PENDING_FOLLOW_UPS :
            return  {
                ...state,
                pendingFollowUps: {
                    ...state.pendingFollowUps,
                    [action.selectedLead.id]: null
                }
            };


        case  actions.ADD_LEAD_TO_TODAY_FOLLOW_UPS :
            return {
                ...state,
                todaysFollowUps: {
                    ...state.todaysFollowUps,
                    [action.selectedLead.id]: action.selectedLead
                }
            };
        case  actions.DELETE_LEAD_FROM_TODAY_FOLLOW_UPS :
            return  {
                ...state,
                todaysFollowUps: {
                    ...state.todaysFollowUps,
                    [action.selectedLead.id]: null
                }
            };

        case  actions.CHECK_CURRENT_PAGE_SELECT_ALL :
            return  {
                ...state,
                currentPageSelectAll : true
            };

        case  actions.UNCHECK_CURRENT_PAGE_SELECT_ALL :
            return  {
                ...state,
                currentPageSelectAll : false
            };

        case  actions.CHECK_SELECT_ALL_LEADS :
            return  {
                ...state,
                selectAllLeads : {
                    isSelectAll: true,
                    currentURL : action.currentURL,
                    currentTab : action.currentTab
                }
            };

        case  actions.UNCHECK_SELECT_ALL_LEADS :
            return  {
                ...state,
                selectAllLeads : {
                    isSelectAll: false,
                    currentURL : ''
                }
            };

        case  actions.REMOVE_SELECTED_LEADS :
            return  {
                ...state,
                allLeads: {},
                newLeads: {},
                todaysFollowUps: {},
                pendingFollowUps: {},
            };

        default :
            return state
    }
};

export default selectedLeads;