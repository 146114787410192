const initialState = {
    type: 'TODAYS_FOLLOWUPS',
    currentURL : null
};

const todaysFollowupPaginationDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'TODAYS_FOLLOWUPS' :
            return action;
        default :
            return state
    }
};

export default todaysFollowupPaginationDetails;