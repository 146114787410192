const initialState = {
    type: 'PENDING_LEADS',
    currentURL : null
};

const pendingLeadsPaginationDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'PENDING_LEADS' :
            return action;
        default :
            return state
    }
};

export default pendingLeadsPaginationDetails;