const initialState = {
    type: 'MEMBERS_WITH_SUBSCRIPTION',
    currentURL : null
};

const membersWithSubscriptionTab = (state = initialState, action) => {
    switch (action.type) {
        case 'MEMBERS_WITH_SUBSCRIPTION' : return action;
        default : return state
    }
};

export default membersWithSubscriptionTab;