const initialState = {
    type: 'EXPIRED_MEMBERS',
    currentURL : null
};

const expiredMembersTab = (state = initialState, action) => {
    switch (action.type) {
        case 'EXPIRED_MEMBERS' : return action;
        default : return state
    }
};

export default expiredMembersTab;