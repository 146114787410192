const initialState = {
    type: 'ALL_LEADS',
    currentURL : null
};

const setPaginationDetails = (state = initialState, action) => {
    switch (action.type) {
        case 'ALL_LEADS' :
            return action;
        default :
            return state
    }
};

export default setPaginationDetails;