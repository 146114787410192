import { combineReducers } from 'redux';
import notification from './notification';
import loader from './loader';
import layoutLoader from './layoutLoader'
import setPaginationDetails from "./leadsTabPagination";
import newLeadsPaginationDetails from "./newLeadsPagination";
import pendingLeadsPaginationDetails from "./pendingFollowUpsPagination";
import todaysFollowupPaginationDetails from "./todaysFollowUpsPagination";
import selectedLeads from "./selectedLeads";
import selectTags from "./selectTags";
import setLeadsOverviewFilter from './leadsOverviewFilter';
import tabsLeadCount from './tabsLeadCount';
import setLeadsDistribution from "./leadsDistribution";
import selectAssignedTo from './selectAssignedTo'
import setLeadsTagTrendsFilter from "./leadInsightsTagTrendsFilter";
import updateLeadTabsCount from "./updateLeadTabsCount";
import paginationData from "./paginationData";
import updateMemberTabsCount from "./updateMemberTabsCount"
import allMembersTab from "./allMembersTab";
import activeMembersTab from "./activeMembersTab";
import expiredMembersTab from "./expiredMembersTab";
import membersWithSubscriptionTab from "./membersWithSubscriptionTab";
import membersWithoutSubscriptionTab from "./membersWithoutSubscriptionTab";
import selectedMembers from "./selectedMembers";
import setMemberPaginationData from "./setMemberPaginationData";
import setAccountBalance from "./accountingBalance"
import selectLeadStatus from "./selectLeadStatus"

export default combineReducers({
    notification,
    loader,
    layoutLoader,
    setPaginationDetails,
    setMemberPaginationData,
    newLeadsPaginationDetails,
    pendingLeadsPaginationDetails,
    todaysFollowupPaginationDetails,
    selectedLeads,
    selectedMembers,
    selectTags,
    setLeadsOverviewFilter,
    setLeadsDistribution,
    setLeadsTagTrendsFilter,
    tabsLeadCount,
    selectAssignedTo,
    updateLeadTabsCount,
    updateMemberTabsCount,
    paginationData,
    allMembersTab,
    activeMembersTab,
    expiredMembersTab,
    membersWithSubscriptionTab,
    membersWithoutSubscriptionTab,
    setAccountBalance,
    selectLeadStatus,
});