const initialState = {
    type: 'LEADS_DISTRIBUTION_FILTER',
    currentFilter : 'Last 28 Days'
};

const setLeadsDistribution = (state = initialState, action) => {
    switch (action.type) {
        case 'LEADS_DISTRIBUTION_FILTER' : return action;
        default : return state;
    }
};

export default setLeadsDistribution;