import React from 'react';
import {IndexRedirect, Route, browserHistory} from 'react-router';
import App from './../App';
import {
    LandingPage, PageNotFoundComponent, Login,
    LeadManagement, LeadDashboard, AllLeads,
    NewLeads, FollowUps, PendingLeads,
    CampaignsComponent, Email, WhatsApp, SMS, Mobile,
    StatisticsComponent, StatsOverview, StatsDistribution, TagTrends, PostPayment, PaymentResponse,
    AccountingManagement, AccountingDashboard, MemberManagement, MemberDashboard, AllMembers, ActiveMembers, ExpiredMembers,
    MembersWithSubscription,  SalesInsights, Products, ActiveProducts, InactiveProducts,AllEarning,AccountingBalance,
    MemberCampaignHistory, MemberEmailCampaignHistory, MemberSMSCampaignHistory, MemberWhatsAppCampaignHistory,
    LeadCampaignHistory, LeadEmailCampaignHistory, LeadSMSCampaignHistory, LeadWhatsAppCampaignHistory,AccountingExpense,
    SettingManagement,SettingDashboard,PDDashboard,QuickSaleDashboard
} from './lazy';

export default (
    <div>
        <Route path="/" history={browserHistory} component={App}>

            <IndexRedirect to="/landing" component={LandingPage}/>

            <Route path="landing" component={LandingPage}/>

            <Route path="login" component={Login}/>

            <Route path="lead" component={LeadManagement}>
                <IndexRedirect to="/lead/dashboard" component={LeadDashboard}/>

                <Route path="dashboard" component={LeadDashboard}>
                    <IndexRedirect to="/lead/dashboard/all" component={AllLeads}/>
                    <Route path="all" component={AllLeads}/>
                    <Route path="new" component={NewLeads}/>
                    <Route path="follow-ups" component={FollowUps}/>
                    <Route path="pending" component={PendingLeads}/>
                </Route>

                <Route path="campaign" component={CampaignsComponent}>
                    <IndexRedirect to="/lead/campaign/email" component={Email}/>
                    <Route path="email" component={Email}/>
                    <Route path="whatsapp" component={WhatsApp}/>
                    <Route path="sms" component={SMS}/>
                    <Route path="mobile" component={Mobile}/>
                </Route>

                <Route path="campaign-history" component={LeadCampaignHistory} >
                    <IndexRedirect to="/lead/campaign-history/email" component={LeadEmailCampaignHistory}/>
                    <Route path="email" component={LeadEmailCampaignHistory}/>
                    <Route path="sms" component={LeadSMSCampaignHistory}/>
                    <Route path="whatsapp" component={LeadWhatsAppCampaignHistory}/>
                </Route>

                <Route path="stats" component={StatisticsComponent}>
                    <IndexRedirect to="/lead/stats/overview" component={StatsOverview}/>
                    <Route path="overview" component={StatsOverview}/>
                    <Route path="distribution" component={StatsDistribution}/>
                    <Route path="trends" component={TagTrends}/>
                </Route>

            </Route>


            <Route path="accounting" component={AccountingManagement}>
                <IndexRedirect to="/accounting/dashboard" component={AccountingDashboard}/>
                <Route path="dashboard" component={AccountingDashboard}>
                    <IndexRedirect to="/accounting/dashboard/earning" component={AllEarning}/>
                    <Route path="earning" component={AllEarning}/>
                    <Route path="balance" component={AccountingBalance}/>
                    <Route path="expense" component={AccountingExpense}/>                    
                </Route>
            
            </Route>


            <Route path="sale" component={SettingManagement}>
                <IndexRedirect to="/sale/dashboard" component={SettingDashboard}/>
                <Route path="dashboard" component={SettingDashboard}>
                    <IndexRedirect to="/sale/dashboard/pos" component={QuickSaleDashboard}/>                                         
                    <Route path="pos" component={QuickSaleDashboard}/>                        
                </Route>
            
            </Route>


            <Route path="members" component={MemberManagement}>
                <IndexRedirect to="/members/dashboard" component={MemberDashboard}/>

                <Route path="dashboard" component={MemberDashboard}>
                    <IndexRedirect to="/members/dashboard/all" component={AllMembers}/>
                    <Route path="active" component={ActiveMembers}/>
                    <Route path="expired" component={ExpiredMembers}/>
                    <Route path="all" component={AllMembers}/>
                    <Route path="pending" component={MembersWithSubscription}/>
                </Route>

                <Route path="campaign-history" component={MemberCampaignHistory}>
                    <IndexRedirect to="/members/campaign-history/email" component={MemberEmailCampaignHistory}/>
                    <Route path="email" component={MemberEmailCampaignHistory}/>
                    <Route path="sms" component={MemberSMSCampaignHistory}/>
                    <Route path="whatsapp" component={MemberWhatsAppCampaignHistory}/>
                </Route>

                <Route path="insights" component={SalesInsights} />

                <Route path="products" component={Products}>
                    <IndexRedirect to="/members/products/active" component={ActiveProducts}/>
                    <Route path="active" component={ActiveProducts}/>
                    <Route path="inactive" component={InactiveProducts}/>
                </Route>
            </Route>

            <Route path="payment" component={PostPayment}>
                <Route path="done" component={PaymentResponse}>
                    <Route path=":payment_request_id" component={PaymentResponse}/>
                </Route>
            </Route>

        </Route>

        <Route path="*" exact="true" component={PageNotFoundComponent}/>
    </div>
);
