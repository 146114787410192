const initialState = {
    type: 'LEADS_OVERVIEW_FILTER',
    currentFilter : 'Last 28 Days'
};

const setLeadsOverviewFilter = (state = initialState, action) => {
    switch (action.type) {
        case 'LEADS_OVERVIEW_FILTER' : return action;
        default : return state;
    }
};

export default setLeadsOverviewFilter;