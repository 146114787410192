const initialState = {
    type: 'MEMBER_PAGINATION_DATA',
    pagination : null
};

const setMemberPaginationData = (state = initialState, action) => {
    switch (action.type) {
        case 'MEMBER_PAGINATION_DATA' : return action;
        default : return state
    }
};

export default setMemberPaginationData;