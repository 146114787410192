import axios from 'axios';
import Constants from './../../../Constants/Constants';

const responseModel = {
    status: 0,
    data : ''
};

const instance = axios.create({
    baseURL: Constants.BASE_URL
});

instance.interceptors.request.use(function (config) {
    let access_token = localStorage.getItem(Constants.ACCESS_TOKEN);
    if(access_token) {
        config.headers.authorization = 'Token '+localStorage.getItem(Constants.ACCESS_TOKEN);
    } else {
        console.log('No access token.');
    }
    return config;
}, function (error) {
    console.error('Error in request interceptor : '+JSON.stringify(error));
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    responseModel.status = response.status;
    responseModel.data = response.data;
    // console.log('Success responseModel : ',responseModel);
    if(response.status !== 200) {
        return Promise.reject(responseModel);
    }
    return responseModel;
}, function (error) {
    responseModel.status = error.response.status;
    responseModel.data = error.response.data;
    // console.log('Error responseModel : ',responseModel);
    return Promise.reject(responseModel);
});

const HttpService =  {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
    patchRequest
};

export default HttpService;

function getRequest(url) {
    return instance.get(url)
        .then(response => {
            return response
        })
        .catch(function (error) {
            return error
        });
}

function postRequest(url, data) {
    return instance.post(url, data)
        .then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
}

function patchRequest(url, data) {
    return instance.patch(url, data, {headers: {"Content-Type": "text/plain"}})
        .then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
}

function putRequest(url, data) {
    return instance.put(url, data, {headers: {"Content-Type": "text/plain"}})
        .then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
}

function deleteRequest(url) {
    return instance.delete(url, {headers: {"Content-Type": "text/plain"}})
        .then(function (response) {
            return response;
        }).catch(function (error) {
            return error;
        });
}

