import React, {Component} from 'react';
import './ReachargeModal.scss';
import HttpService from "../../../AppModule/Services/HttpService";
import Constants from "../../../../Constants/Constants";
import {setToasterNotification, startLoader} from "../../../../Store/Actions";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import $ from 'jquery';
import Labels from "../../../../Constants/Labels";

class RechargeModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
          packagesList : [],
          subscriptionPackages : [],
          addOnPackages : [],
          purchasedSubscriptionPackages : [],
          purchasedAddOnPackages : [],
          package : {
              total_price : 0,
              packages : []
          }
        };
        this.resetModal = this.resetModal.bind(this);
    }

    componentDidMount() {
        var rechargeModal = this;
        this.isDataReceived = false;
        $('#rechargeNowModal').on('shown.bs.modal', function (e) {
            rechargeModal.getPackagesList();
        });
    }

    getPackagesList() {
        // this.startLayoutLoader();
        let PACKAGES_URL = Constants.API.PACKAGES;
        HttpService.getRequest(PACKAGES_URL).then(response => {
            if (response.status !== 200) {
                this.errorGettingPackages(response);
            } else {
                this.successGettingPackages(response);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    errorGettingPackages(response) {
        this.isDataReceived = true;
        this.props.setToasterNotification({
            type: 'SET_FLAG',
            responseType: Constants.RESPONSE_TYPE_ERROR,
            message: Constants.ERROR_GETTING_PACKAGES_DETAILS
        });
    }

    successGettingPackages(response) {
        let allPackagesList = response.data;
        this.isDataReceived = true;
        this.sortPackagesList(allPackagesList);
    }

    startLayoutLoader() {
        this.props.dispatch(startLoader('true'));
    }

    stopLayoutLoader() {
        this.props.dispatch(startLoader('false'));
    }

    sortPackagesList(allPackagesList) {
        let subscriptionPackagesList = allPackagesList.filter(function (packageObj) {
            return packageObj.type === 1;
        });
        let addOnPackagesList = allPackagesList.filter(function (packageObj) {
            return packageObj.type === 2;
        });
        this.setState({
            packagesList : allPackagesList,
            subscriptionPackages : subscriptionPackagesList,
            addOnPackages : addOnPackagesList,
        });
        // this.stopLayoutLoader();
    }

    selectAddOnPackage =(packageObject, event) => {
        let addOnPackageList = this.state.purchasedAddOnPackages;
        if (event.target.checked) {
            addOnPackageList.push(packageObject);
        } else {
            addOnPackageList = addOnPackageList.filter((item) => item.id !== packageObject.id);
        }
        this.setState({
            purchasedAddOnPackages : addOnPackageList
        }, function () {
            this.calculateTotalPurchasePrice();
        });
    };

    addSubscriptionPackage(subscriptionPackage, event) {
        let purchasedSubscription = this.state.purchasedSubscriptionPackages;
        if(event.target.checked) {
            if(purchasedSubscription.length >= 0) {
                this.setState({
                    purchasedSubscriptionPackages : [subscriptionPackage]
                }, function () {
                    this.calculateTotalPurchasePrice();
                });
            }
        } else {
            this.setState({
                purchasedSubscriptionPackages : []
            },function () {
                this.calculateTotalPurchasePrice();
            });
        }
    }

    calculateTotalPurchasePrice() {
        let subscriptionPackages = this.state.purchasedSubscriptionPackages;
        let addOnPackages = this.state.purchasedAddOnPackages;
       let total_price = 0;
       let packages = [];
       for(let subscription of subscriptionPackages) {
           total_price = total_price + subscription.price;
           packages.push(subscription.id);
       }
        for(let addOnPackage of addOnPackages) {
            total_price = total_price + addOnPackage.price;
            packages.push(addOnPackage.id);
        }
        this.setState({
            package : {
                packages : packages,
                total_price : total_price
            }
        });
    }

    proceedToPay=() => {
        let ORDER_URL = Constants.API.ORDER;
        let paymentBody = this.state.package;
        HttpService.postRequest(ORDER_URL, paymentBody).then(response => {
            if (response.status !== 200) {
                this.errorCreatingOrder(response);
            } else {
                this.successCreatingOrder(response);
            }
        });
    };

    errorCreatingOrder=(error) => {
        this.props.setToasterNotification({
            type: 'SET_FLAG',
            responseType: Constants.RESPONSE_TYPE_ERROR,
            message: Constants.ERROR_CREATING_ORDER
        });
    };

    successCreatingOrder=(response) => {
        let paymentURL = response.data.payment_redirect_url;
        window.location = paymentURL;
    };

    isSubscriptionPackageSelected =(packageId) => {
        let packageList = this.state.purchasedSubscriptionPackages;
        let subscriptionPack = packageList.filter(function (subPackage) {
            return subPackage.id === packageId;
        });
        if(subscriptionPack.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    isAddOnPackageSelected = (packageId) =>{
        let packageList = this.state.purchasedAddOnPackages;
        let addonPack = packageList.filter(function (subPackage) {
            return subPackage.id === packageId;
        });
        if(addonPack.length > 0) {
            return true;
        } else {
            return false;
        }
    };

    resetModal(){
        $('#collapseExample').collapse('hide');
        this.setState({
            purchasedSubscriptionPackages : [],
            purchasedAddOnPackages : [],
            package : {
                total_price : 0,
                packages : []
            }
        })
    }

    render() {

        const subscriptionPlanEndDate = localStorage.getItem(Constants.SUBSCRIPTION_END_DATE);

        return (
                <div className="modal fade" id="rechargeNowModal" role="dialog"
                     aria-labelledby="rechargeNowModalTitle" aria-modal="true" data-backdrop="static">
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">

                        <div className="modal-content">

                            <div className="modal-header modal-custom-header">
                                <div className="inner-container">
                                    <h5 className="modal-title text-center" id="rechargeNowModalTitle">{Labels.HEADINGS.RECHARGE}</h5>
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.resetModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="plans-container">

                                    <div className="special-recharge-plans-container">
                                        <div className="body-header">
                                            <div className="special-recharge-title">
                                                <span className="font-weight-bold purple-text">{Labels.HEADINGS.SPECIAL_RECHARGE}</span>
                                                <span>{Labels.SUBHEADINGS.MULTIPLE_SELECT_PLANS}</span>
                                            </div>
                                        </div>
                                        {
                                            this.isDataReceived ?
                                                this.state.addOnPackages.length > 0 ?
                                                    <ul className="special-recharge-plans">
                                                        {this.state.addOnPackages.map((addOnPackage,index) =>
                                                            <li key={addOnPackage.id}>
                                                                <div className="custom-control custom-checkbox checkbox-card-container">
                                                                    <input type="checkbox" className="custom-control-input" id={"customCheck"+index}
                                                                           checked={this.isAddOnPackageSelected(addOnPackage.id)}
                                                                           onChange={(event) => this.selectAddOnPackage(addOnPackage, event)} />
                                                                    <label className="custom-control-label" htmlFor={"customCheck"+index}></label>
                                                                    <div className="checkbox-card">
                                                                        <div className="recharge-amount pink-text font-weight-bold text-center">
                                                                            &#x20b9; {addOnPackage.price}/-
                                                                        </div>
                                                                        <div className="recharge-name text-center">{addOnPackage.name}</div>
                                                                        {
                                                                            addOnPackage.package_values.sort((prev, next) => prev.order - next.order).map((values, valueIndex) =>
                                                                                <ul key={valueIndex} className="recharge-features-list dot-bullets">
                                                                                    {
                                                                                        values.desc.includes("Valid") ?
                                                                                        <li className="font-weight-bold">{values.desc}</li>
                                                                                        : <li>{values.desc}</li>
                                                                                    }
                                                                                </ul>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                : <div>Currently no addon packages available. Please contact Administrator.</div>
                                            : <table className="table table-loader"></table>
                                        }
                                    </div>

                                    <div className="yearly-plans">
                                        <div className="subscription-ending-message">
                                            {Labels.SUBHEADINGS.SUBSCRIPTION_PLAN_END_DATE} <b>{subscriptionPlanEndDate}</b>
                                            <a className="link-primary" data-toggle="collapse"
                                               href="#collapseExample" role="button" aria-expanded="false"
                                               aria-controls="collapseExample">{Labels.ACTION_BUTTONS.ADD_NOW}</a>
                                        </div>
                                        <div className="collapse" id="collapseExample">
                                            <div className="special-recharge-title subscription-plans-title">
                                                <span className="font-weight-bold purple-text">{Labels.HEADINGS.SUBSCRIPTION_PLANS}</span>
                                                <span> {Labels.SUBHEADINGS.SELECT_SUBSCRIPTION_PLAN}</span>
                                            </div>
                                            {
                                                this.isDataReceived ?
                                                this.state.subscriptionPackages.length > 0 ?
                                                    <ul className="subscription-plans">
                                                        {this.state.subscriptionPackages.map((subscriptionPackage,index) =>
                                                            <li key={index}>
                                                                <div className="custom-control custom-checkbox checkbox-card-container">
                                                                    <input type="checkbox" className="custom-control-input" id={"subscription-plan"+index}
                                                                           checked={this.isSubscriptionPackageSelected(subscriptionPackage.id)}
                                                                           onChange={(event) => this.addSubscriptionPackage(subscriptionPackage, event)} />
                                                                    <label className="custom-control-label round-checkbox" htmlFor={"subscription-plan"+index}></label>
                                                                    <div className="checkbox-card">
                                                                        <div className="card-icon paperplane-icon"></div>
                                                                        <div className="recharge-amount purple-text font-weight-bold text-center">
                                                                            &#x20b9; {subscriptionPackage.price} /-
                                                                        </div>
                                                                        <div className="recharge-name text-center">{subscriptionPackage.name}</div>
                                                                        {
                                                                            subscriptionPackage.package_values.sort((prev, next) => prev.order - next.order).map((values, index) =>
                                                                                <ul key={index} className="recharge-features-list dot-bullets">
                                                                                    {
                                                                                        values.desc.includes("Valid") ?
                                                                                        <li className="font-weight-bold">{values.desc}</li>
                                                                                        : <li>{values.desc}</li>
                                                                                    }
                                                                                </ul>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                    : <div>Currently no subscription packages available. Please contact Administrator.</div>
                                                : <table className="table table-loader"></table>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={this.proceedToPay} disabled={this.state.package.total_price === 0}>
                                    {Labels.ACTION_BUTTONS.PAY}<span>&#x20b9; {this.state.package.total_price}/-</span>
                                </button>
                                <button type="button" className="btn btn-default" onClick={this.resetModal} data-dismiss="modal">{Labels.ACTION_BUTTONS.CANCEL}</button>
                            </div>
                        </div>

                    </div>
                </div>
       );
    }

}

const mapdispatchToProps = dispatch => {
    return {
        setToasterNotification: (response) => dispatch(setToasterNotification(response))
    };
};

export default connect(null, mapdispatchToProps)(RechargeModal);
