const initialState = {
    type: 'ACTIVE_MEMBERS',
    currentURL : null
};

const activeMembersTab = (state = initialState, action) => {
    switch (action.type) {
        case 'ACTIVE_MEMBERS' : return action;
        default : return state
    }
};

export default activeMembersTab;