import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-checkbox-tree-enhanced/lib/react-checkbox-tree.css';
import 'bootstrap';
import ReactDOM from "react-dom";
import { Router, browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import routes from './Modules/routes';
import store from './Store/store';

ReactDOM.render(
    <Provider store={store}>
        <Router history={browserHistory} routes={routes}></Router>
    </Provider>,
    document.getElementById("root"));