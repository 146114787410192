const initialState = {
    type: 'SET_FLAG',
    responseType : 'nothing',
    message : 'No Message to Display'
};

const notification = (state = initialState, action) => {
    return {
        ...state,
        ['responseType']: action.responseType,
        ['message']: action.message,
    }
};

export default notification;