const initialState = {
    type: 'MEMBERS_WITHOUT_SUBSCRIPTION',
    currentURL : null
};

const membersWithoutSubscriptionTab = (state = initialState, action) => {
    switch (action.type) {
        case 'MEMBERS_WITHOUT_SUBSCRIPTION' : return action;
        default : return state
    }
};

export default membersWithoutSubscriptionTab;