const initialState = {
    type: 'UPDATE_MEMBER_TABS_COUNT',
    flag : false
};

const updateMemberTabsCount = (state = initialState, action) => {
    if(action && action.type) {
        switch (action.type) {
            case 'UPDATE_MEMBER_TABS_COUNT' :
                return action;
            default :
                return state;
        }
    }
};

export default updateMemberTabsCount;