const initialState = {
    type: 'CLEAR_SELECT_TAGS',
    isClearSelectedTags : false
};

const selectTags = (state = initialState, action) => {
    return {
       ...state,
        isClearSelectedTags:action.isClearSelectedTags
    }
};

export default selectTags;