import React from 'react';
import { connect } from 'react-redux';
import './AppLoader.scss';

const AppLoader = ({data}) => (
    <React.Fragment>
        { (data.startLoader === 'true') ? <div className="app-loader show-loader"><div className="loader-logo"></div></div> : null }
    </React.Fragment>
);

const mapStateToProps = state => ({
    data: state.loader
});

export default connect(mapStateToProps)(AppLoader)
