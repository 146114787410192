const Constants = {

    // BASE_URL : 'http://127.0.0.1:8000/api/',
    BASE_URL : 'http://www.twistoe.in/api/',
    DEV_HOST_URL :'http://localhost:3000/',
    API_LEAD_URL : "v1/leads/?",
    API_STARTPOINT : '/api/',
    GET_ALL_LEADS_TAB_COUNT : 'v1/leads/stats/',
    USERS_PREFERENCES : 'v1/users/preferences/',
    GET_ALL_LEADS : 'v1/leads/',
    GET_ALL_MEMBERSHIP_CARDS : 'v1/business/config/mcards/',
    GET_ASSIGNED_USERS : 'v1/users/',
    GET_PAYMENT_MODE : 'v1/accounting/paymentmode/',
    GET_ALL_EXPENSES : 'v1/accounting/expense/',
    GET_ALL_EARNING : 'v1/accounting/earning/',
    SEARCH_CUSTOMER:  'v1/business/customer/search/',

    USER_ROLE_TYPE: {
        ADMIN:2,
        OPERATOR:1,
        DEFAULT:0
    },

    CARD_TYPE: {
        WALLET:1,
        TX:2,
        POINTS:3
    },
    TWISTOE: {
        MEMBER_MANAGEMENT: 'Member',
        LEAD_MANAGEMENT: 'Lead'
    },

    PROJECT_ASSETS : {
      MEMBER_SUBSCRIPTION_EXPIRY_DAYS_LIMIT : -30
    },

    MANAGE_SUBSCRIPTIONS: {
        RENEW_SUBSCRIPTION: 'Renew Subscription',
        SEND_RENEWAL_REMINDER: 'Send Renewal Reminder',
        EDIT_SUBSCRIPTION: 'Edit Subscription',
        DELETE_SUBSCRIPTION: 'Delete Subscription',
        NO_AVAILABLE_SUBSCRIPTION: 'No subscriptions are available'
    },

    ACCOUNT_PAGE_LIMIT : '?ordering=-created_date&limit=$limit$',
    PAGE_LIMIT : '?ordering=-lead_date&limit=$limit$',
    FOLLOW_UP_DATE : '&nextfollowupdate',
    PENDING :'&nextfollowupdate__lt=$condition$',
    FOLLOW_UPS :'&nextfollowupdate__gt=$todaysDate$&nextfollowupdate__lt=$tomorrowDate$',
    NAME_FILTER:'&name__icontains=$name$',
    MOBILE_NUMBER_FILTER : '&mobile__icontains=$mobileNumber$',
    CREATED_DATE_FILTER : '&lead_date__gte=$fromDate$&lead_date__lte=$toDate$',
    EXPENSE_DATE_FILTER : '&created_date__gte=$fromDate$&created_date__lte=$toDate$',
    EXPENSE_AMOUNT_FILTER : '&amount=$expense_amount$',
    EXPENSE_NOTES_FILTER : '&notes__icontains=$notes$',
    FOLLOW_UP_DATE_FILTER : '&nextfollowupdate__gte=$fromDate$&nextfollowupdate__lte=$toDate$',
    PENDING_FOLLOW_UP_DATE_FILTER : '&nextfollowupdate__lte=$toDate$',
    TAGS_FILTER :'&tags__name__in=$tags$',
    ASSIGNED_TO_FILTER : '&assigned_to__first_name__contains=$firstName$&assigned_to__last_name__contains=$lastName$',
    PAYMENT_MODE_FILTER : '&payment_mode=$paymentmode$',
    LEAD_STATUS_FILTER : '&status=$status$',
    FOLLOW_UP_HISTORY : '$lead_id$/followups/',

    CREATE : 'Create',
    LOGIN : 'Login',
    CANCEL : 'Cancel',

    REGISTER : 'Register',

    DUPLICATE_TEMPLATE : 'Duplicate Template',
    DUPLICATE_TEMPLATE_SUFFIX :' - duplicate',

    PAGE_LIMIT_LIST : [25, 50, 75, 100],
    PAGE_LIMIT_LABEL : 'page_limit',
    PAGE_LIMIT_MEMBER_LABEL : 'page_member_key',
    PAGE_LIMIT_CAMPAIGN_LABEL : 'page_campaign_key',
    ACCESS_TOKEN: 'access_token',
    HEADERS_TOKEN: 'Token ',
    SUBSCRIPTION_END_DATE: 'subscription_end_date',
    USER_NAME: 'user_name',
    USER_ROLE: 'user_role',

    RESPONSE_TYPE_SUCCESS : 'success',
    RESPONSE_TYPE_ERROR : 'error',
    RESPONSE_TYPE_NOTHING : 'nothing',

    // Success Messages
    SUCCESS_SIGNUP: 'We hava sent your account details on your email!',
    SUCCESS_UPDATE_EXPENSE : 'Successfully updated Expense.',
    SUCCESS_CALLBACK: 'Your request for callback has reached us. We will contact you shortly.',
    SUCCESS_CREATE_EXPESE : 'Successfully added Expense.',
    SUCCESS_CREATE_LEAD : 'Successfully created Lead.',
    SUCCESS_UPDATE_LEAD : 'Successfully updated Lead.',
    SUCCESS_LEAD_TO_MEMBER : 'Successfully Lead converted to Member.',
    SUCCESS_UPDATE_LEAD_TAGS : 'Successfully updated Lead Tags.',
    SUCCESS_GET_ADMIN_DETAILS : 'Successfully received Admin details.',

    SUCCESS_LOGIN : 'Successfully Logged In',
    SUCCESS_LOGOUT : 'Successfully Logged out',
    ERROR_LOGIN : 'Invalid Credentials',
    ERROR_LOGOUT : 'Error in logging out',

    SUCCESS_GET_LEADS_LIST : 'Successfully in getting Leads list',
    SUCCESS_GET_LEADS_COUNT : 'Successfully in getting Leads count',

    SUCCESS_UPDATE_MULTIPLE_LEADS : 'Successfully updated Selected Leads',
    SUCCESS_UPDATE_TAGS_MULTIPLE_LEADS : 'Successfully updated Tags for Leads',
    SUCCESS_UPDATE_FOLLOWUP_MULTIPLE_LEADS : 'Successfully updated FollowUp Date for Leads',
    SUCCESS_UPDATE_STATUS_MULTIPLE_LEADS : 'Successfully updated Status for Leads',
    SUCCESS_UPDATE_COMMENTS_MULTIPLE_LEADS : 'Successfully updated Comments for Leads',

    SUCCESS_DELETE_TEMPLATE :'Successfully deleted $name$ template',
    SUCCESS_DUPLICATE_TEMPLATE :'Successfully duplicated to "$name$" template',
    SUCCESS_SEND_TEMPLATE : 'Campaign submitted. Check status under Campaign Report section.',
    PENDING_SEND_TEMPLATE : 'Campaign submitted. Check status under Campaign Report section.',

    SUCCESS_EMAIL_TEMPLATE_SAVE : 'Email template saved successfully',
    SUCCESS_EMAIL_TEMPLATE_UPDATE : 'Email template updated successfully',


    SUCCESS_WHATSAPP_TEMPLATE_SAVE : 'WhatsApp template saved successfully.',
    SUCCESS_WHATSAPP_TEMPLATE_UPDATE : 'WhatsApp template updated successfully.',

    SUCCESS_MOBILE_TEMPLATE_SAVE : 'Mobile template saved successfully.',
    SUCCESS_MOBILE_TEMPLATE_UPDATE : 'Mobile template updated successfully.',

    SUCCESS_SMS_TEMPLATE_SAVE : 'SMS template saved successfully',
    SUCCESS_SMS_TEMPLATE_UPDATE : 'SMS template updated successfully',

    SUCCESS_ADD_FOLLOWUP_LEAD : 'Added followup successfully.',

    SUCCESS_CONVERT_LEAD_MEMBER: 'Successfully converted the lead to member.',
    SUCCESS_ADD_MEMBER: 'Successfully added the member.',
    SUCCESS_EDIT_MEMBER: 'Successfully updated the member.',
    SUCCESS_ADD_PRODUCT_OFFERING_DETAILS: 'Successfully added the product offering details.',
    SUCCESS_ADD_PRODUCT_OFFERING_SUBSCRIBE: 'Successfully subscribed to the product offering.',
    SUCCESS_ADD_PRODUCT_OFFERING_DETAILS_RENEWED: 'Successfully renewed the subscription.',
    SUCCESS_ADD_PRODUCT_OFFERING_DETAILS_EDITED: 'Successfully edited the subscription.',
    SUCCESS_ADD_PRODUCT_OFFERING: 'Successfully added the offering.',
    SUCCESS_EDIT_PRODUCT_OFFERING: 'Successfully edited the offering.',
    SUCCESS_DEACTIVATE_PRODUCT_OFFERING: 'Successfully deactivated the offering.',
    SUCCESS_ACTIVATE_PRODUCT_OFFERING: 'Successfully activated the offering.',
    SUCCESS_DELETED_PRODUCT_OFFERING: 'Successfully deleted the offering.',
    SUCCESS_CREATE_PRODUCT: 'Successfully created the product.',
    SUCCESS_EDITED_PRODUCT: 'Successfully edited the product.',
    SUCCESS_DELETE_PRODUCT_OFFERING: 'Successfully deleted the product.',
    SUCCESS_DELETE_SUBSCRIPTION: 'Successfully deleted the subscription.',
    SUCCESS_DELETE_MEMBER: 'Successfully deleted the member.',
    SUCCESS_SEND_RENEWAL_REMINDER: 'Successfully send renewal reminder to member.',

    // Error Messages
    ERROR_UPDATE_EXPENSE : 'Error in updating Expense.',
    ERROR_SIGNUP: 'Error in Sign Up.',
    ERROR_SIGNUP_USER_EXIST: 'You already have a account. Please login.',
    ERROR_CALLBACK: 'Error creating.',
    ERROR_CREATE_EXPENSE : 'Error in adding Expense.',
    ERROR_CREATE_LEAD : 'Error in creating Lead.',    
    ERROR_UPDATE_LEAD : 'Error in updating Lead.',
    ERROR_UPDATE_LEAD_TAGS : 'Error in updating Lead Tags.',

    ERROR_UPDATE_MULTIPLE_LEADS : 'Error updating Selected Leads',
    ERROR_UPDATE_TAGS_MULTIPLE_LEADS : 'Error updating Tags for Leads',
    ERROR_UPDATE_FOLLOWUP_MULTIPLE_LEADS : 'Error updating FollowUp Date for Leads',
    ERROR_UPDATE_STATUS_MULTIPLE_LEADS : 'Error updating Status for Leads',
    ERROR_UPDATE_COMMENTS_MULTIPLE_LEADS : 'Error updating Comments for Leads',

    ERROR_GET_LEADS_LIST : 'Error in getting Leads list.',
    ERROR_GET_LEADS_COUNT : 'Error in getting Leads count',
    ERROR_GET_MEMBERS_COUNT : 'Error in getting Members count',

    ERROR_CREATE_SMS : 'Error in creating SMS Template',
    ERROR_UPDATE_SMS : 'Error in updating SMS Template',

    ERROR_CREATE_WHATSAPP : 'Error in creating WhatsApp Template',
    ERROR_UPDATE_WHATSAPP : 'Error in updating WhatsApp Template',

    ERROR_CREATE_MOBILE: 'Error in creating Mobile Template',
    ERROR_UPDATE_MOBILE : 'Error in updating Mobile Template',

    ERROR_DELETE_TEMPLATE : 'Error in delete $name$ template',
    ERROR_DUPLICATE_TEMPLATE : 'Error in duplicating $name$ template',
    ERROR_DUPLICATE_TEMPLATE_NAME : 'Template name already exists, please change the name',
    ERROR_SEND_TEMPLATE : 'Error sending template',

    ERROR_EMAIL_TEMPLATE_SAVE : 'Error saving template',
    ERROR_EMAIL_TEMPLATE_UPDATE : 'Error updating template',

    ERROR_ADD_FOLLOWUP_LEAD : 'Error in adding followup lead.',
    ERROR_NOT_VERIFIED_USER_SEND_TEMPLATE : 'This campaign is not yet verified for use in your account. Please contact admin to get it verified or use other campaigns.',

    ERROR_CREATE_MEMBER:'Error creating member.',
    ERROR_EDITING_MEMBER:'Error editing member.',
    ERROR_DELETE_SUBSCRIPITON:'Error in deleting subscription.',
    ERROR_DELETE_MEMBER:'Error removing member.',
    ERROR_ADDING_PRODUCT_OFFERING_DETAILS:'Error adding product offering details.',
    ERROR_RENEW_SUBSCRIPTION:'Error renewing subscription.',
    ERROR_SUBSCRIBING:'Error subscribing.',
    ERROR_ADDING_PRODUCT_OFFERING_EDIT:'Error editing subscription.',
    ERROR_CREATE_PRODUCT_OFFERING:'Error adding offering.',
    ERROR_DEACTIVATE_PRODUCT_OFFERING:'Error deactivating offering.',
    ERROR_ACTIVATE_PRODUCT_OFFERING:'Error activating offering.',
    ERROR_DELETE_PRODUCT_OFFERING:'Error deleting offering.',
    ERROR_EDIT_PRODUCT_OFFERING:'Error in editing the offering.',
    ERROR_EDIT_PRODUCT:'Error in editing the product.',
    ERROR_CREATE_PRODUCT:'Error adding Product.',
    ERROR_SEND_RENEWAL_REMINDER: 'Error in sending renewal reminder to member.',
    ERROR_GET_CAMPAIGN_CREDITS: 'Error in getting campaign credits.',
    ERROR_GET_EMAIL_TEMPLATE_LISTING: 'Error in getting Email template listing.',
    ERROR_GET_MOBILE_TEMPLATE_LISTING: 'Error in getting Mobile template listing.',
    ERROR_GET_SMS_TEMPLATE_LISTING: 'Error in getting SMS template listing.',
    ERROR_GET_WHATSAPP_TEMPLATE_LISTING: 'Error in getting Whatsapp template listing.',
    ERROR_GET_CAMPAIGN_HISTORY: 'Error in getting campaign history.',

    ERROR_GET_LEAD_CAMPAIGN_HISTORY_EMAIL: 'Error in getting lead campaign history for Email.',
    ERROR_GET_LEAD_CAMPAIGN_HISTORY_SMS: 'Error in getting lead campaign history for SMS.',
    ERROR_GET_LEAD_CAMPAIGN_HISTORY_WHATSAPP: 'Error in getting lead campaign history for Whatsapp.',
    ERROR_GET_MEMBER_CAMPAIGN_HISTORY_EMAIL: 'Error in getting lead campaign history for Email.',
    ERROR_GET_MEMBER_CAMPAIGN_HISTORY_SMS: 'Error in getting lead campaign history for SMS.',
    ERROR_GET_MEMBER_CAMPAIGN_HISTORY_WHATSAPP: 'Error in getting lead campaign history for Whatsapp.',

    ERROR_GETTING_TAGS_LIST : 'Error in getting tags list.',
    ERROR_GETTING_USERS_LIST : 'Error in getting assigned users list.',
    ERROR_GETTING_PAYMENTMODE_LIST : 'Error in getting payment mode list.',
    ERROR_UPDATE_PAGE_LIMIT : 'Error in updating user preferences.',
    ERROR_GETTING_FOLLOWUP_HISTORY : 'Error in getting followup history.',
    ERROR_GETTING_NOTIFICATIONS : 'Error in getting notifications.',

    ERROR_GETTING_ACCOUNTING_BALANCE : 'Error in getting accounting data.',

    ERROR_GETTING_LEAD_SOURCE_DATA : 'Error in getting lead sources data.',
    ERROR_GETTING_CONVERTED_LEADS : 'Error in getting converted leads data.',
    ERROR_GETTING_GENERATED_LEADS : 'Error in getting generated leads data.',
    ERROR_GETTING_TAG_COMPARISON_DATA : 'Error in getting tag comparison data.',
    ERROR_GETTING_TAG_COMPARISON_NO_TAGS : 'Add tags to compare.',
    ERROR_GETTING_TOTAL_SALES_DATA : 'Error in getting tag comparison data.',

    ERROR_GET_MEMBERS_LIST : 'Error in getting members list.',
    ERROR_GET_ACTIVE_PRODUCTS_LIST : 'Error in getting active products list.',
    ERROR_GET_INACTIVE_PRODUCTS_LIST : 'Error in getting inactive products list.',
    ERROR_GETTING_CAMPAGIN_REPORT : 'Error getting campaign report details.',
    ERROR_GETTING_PAYEMNT_ORDER_DETAILS : 'Error getting payment order details.',
    ERROR_GETTING_PACKAGES_DETAILS : 'Error getting packages details.',
    ERROR_CREATING_ORDER : 'Error creating order.',

    //Email Template
    NEW_EMAIL_TEMPLATE : 'New Email Template',
    NEW_SMS_TEMPLATE : 'New SMS Template',
    NEW_WHATSAPP_TEMPLATE : 'New WhatAapp Template',
    NEW_MOBILE_TEMPLATE : 'New Mobile Template',

    CREATE_EMAIL_TEMPLATE : 'Create Email Template',
    EDIT_EMAIL_TEMPLATE : 'Edit Email Template',
    PREVIEW_EMAIL_TEMPLATE : 'Preview E-mail Campaign',

    //SMS template
    CREATE_SMS_TEMPLATE : "Create SMS Template",
    EDIT_SMS_TEMPLATE : "Edit SMS Template",
    PREVIEW_SMS_TEMPLATE: 'Preview SMS Campaign',

    //Update
    UPDATE: "Update",

    //WhatsApp Template
    CREATE_WHATSAPP_TEMPLATE : "Create WhatsApp Template",
    EDIT_WHATSAPP_TEMPLATE : "Edit WhatsApp Template",
    PREVIEW_WHATSAPP_TEMPLATE: "Preview WhatsApp Template",
    //Mobile template
    CREATE_MOBILE_TEMPLATE : "Create Mobile Template",
    EDIT_MOBILE_TEMPLATE : "Edit Mobile Template",
    PREVIEW_MOBILE_TEMPLATE: 'Preview Mobile Campaign',

    TEMPLATE_NAME : 'Template Name',
    CONTENT_TYPE : 'Content Type',
    DESCRIPTION : 'Description',
    SUBJECT : 'Subject',
    PASTE_IMAGE_URL : 'Paste Image URL',
    SAVE_TEMPLATE : 'Save Template',
    EDIT_TEMPLATE : 'Edit Template',
    TINY_MCE_API_KEY : 'u941up13igke4kuw32sa6quhltqbr7sujvme46hr8is0pxby',
    ACTIVE: 'Active',

    MAXIMUM_TAGS : 5,

    API : {
      CALLBACK:'v1/leads/callback/',
      SIGNUP:'v1/signup/',
      GET_ALL_EXPENSES : 'v1/accounting/expense/',
      LOGIN: 'v1/login/',
      LOGOUT : 'v1/logout/',
      
      GET_ALL_LEADS : 'v1/leads/',
      MEMBERSHIP_CARDS_SETTINGS : 'v1/business/config/mcards/',
      LEADS_ASSIGNED_TO : 'v1/leads/users/',
      GET_TAG_LIST:'v1/studio/lead/tags/',

      EMAIL : 'v1/leads/template/email/',
      SMS : 'v1/leads/template/sms/',
      WHATSAPP : 'v1/leads/template/whatsapp/',
      WHATSAPP_STUDIO_IMAGES: 'v1/studio/images/',
      MOBILE : 'v1/leads/template/',
      TAGS: 'v1/leads/tags/',
      FOLLOWUPS_COMMENTS_TO__MULTIPLE_LEADS : 'v1/leads/followup/',
      FOLLOWUPS_COMMENTS_TO_LEAD : 'v1/leads/followup/',
      STATUS: 'v1/leads/',
      COMMENTS: 'v1/leads/comments/',
      CAMPAIGN_CREDITS : 'v1/credits/',
      CAMPAIGN_PACKAGE_HISTORY : 'v1/packages/history/',

      DELETE_EMAIL_TEMPLATE :'v1/leads/template/email/$template_id$',
      DELETE_SMS_TEMPLATE :'v1/leads/template/sms/$template_id$',
      DELETE_WHATSAPP_TEMPLATE :'v1/leads/template/whatsapp/$template_id$',
      DELETE_MOBILE_TEMPLATE :'v1/leads/template/$template_id$',
      LEADS_EMAIL :'v1/leads/email/',
      LEADS_SMS :'v1/leads/sms/',
      LEADS_WHATSAPP :'v1/leads/whatsapp/',
      LEADS_MOBILE :'v1/leads/mobile/',

      PACKAGES : 'v1/packages/',
      ORDER : 'v1/packages/order/',

      LEAD_GENERATION_INSIGHTS : 'v1/insight/leads/created?lead_date__range=',
      LEAD_CONVERTED_AND_CAMPAIGNS_INSIGHTS : 'v1/insight/leads/status/daywise?lead_date__range=',
      LEAD_CAMPAIGNS_INSIGHTS : 'v1/insight/campaigns?created_date__range=',
      LEADS_STATISTICS_COMPARISON : 'v1/insight/leads/status?status_change_date__range=',

      ACCOUNT_BALANCE_INSIGHTS : 'v1/accounting/balance/?created_date__range=',
      // Stats Distribution
      LEAD_INSIGHTS_CONVERTED_TO_LEADS : 'v1/insight/leads/conversion/userwise?lead_date__range=',
      LEAD_INSIGHTS_SOURCE : 'v1/insight/leads/conversion/sourcewise?lead_status_date=',

      START_CAMPAIGN_ALL : 'v1/leads/campaigns/all/',
      START_CAMPAIGN : 'v1/leads/campaigns/',

      START_MEMBER_CAMPAIGN_ALL : 'v1/subscribers/campaigns/all/',
      START_MEMBER_CAMPAIGN : 'v1/subscribers/campaigns/',

      DELETE_SUBSCRIPTION: 'v1/subscriber/offering/$id$/',
      DELETE_MEMBER: 'v1/subscriber/remove',

      // Stats tag trends
      TAGS_COMPARISON: 'v1/insight/leads/tags/compare/?start_date=$startDate$&time=D&tags=$tagsList$',
      TOP_5_TRENDING_TAGS : 'v1/insight/leads/top/tags/?start_date=$startDate$',

      LEAD_NOTIFICATION :'v1/notifications/',
      BATCH_UPDATE :'v1/batch/',

      MEMBER_PRODUCTS :'v1/products/?ordering=name',
      MEMBER_DELETE_PRODUCT :'v1/products/$product_id$/',
      ACTIVE_PRODUCTS :'?active=true',
      INACTIVE_PRODUCTS :'?active=false',
      MEMBER:'v2/subscriber/',
      SEND_RENEWAL_REMINDER:'v1/subscriber/reminder/',
      PRODUCT_OFFERING_DETAILS: 'v1/subscriber/offering',
      ADD_PRODUCT_OFFERING:'v1/offerings/',

      MEMBERS_COUNT:'v1/subscriber/stats/',
      MEMBERS_LIST : 'v2/subscriber/?limit=$page_limit$&offset=$page_offset$&ordering=-joining_date&',
      EXPIRED_MEMBERS : 'event_members__extended_expiry__lt=$todays_date$',
      ACTIVE_MEMBERS : 'event_members__extended_expiry__gte=$todays_date$&',
      ACTIVE_MEMBERS_EVENT_MEMBERS : 'event_members__isdeleted=false&',
      PENDING_MEMBERS : 'event_members__isnull=True&',

      MEMBER_SALES_INSIGHTS : 'v1/insight/sales/?created_date__range=',
      SALES_INSIGHTS_EVENT_ID: '&event__id__in=$event__id__in',


      Lead_EMAIL_CAMPAIGN_HISTORY : 'v1/insight/leads/campaigns/report/?campaign_type=email&limit=$page_limit$&offset=0&ordering=-created_date',
      Lead_SMS_CAMPAIGN_HISTORY : 'v1/insight/leads/campaigns/report/?campaign_type=sms&limit=$page_limit$&offset=0&ordering=-created_date',
      Lead_WHATSAPP_CAMPAIGN_HISTORY : 'v1/insight/leads/campaigns/report/?campaign_type=whatsapp&limit=$page_limit$&offset=0&ordering=-created_date',

      DOWNLOAD_CAMPAGIN_REPORT : 'v1/insight/campaigns/report/download/$campaign_id$',

      MEMBER_EMAIL_CAMPAIGN_HISTORY : 'v1/insight/subscribers/campaigns/report/?campaign_type=email&limit=$page_limit$&offset=0&ordering=-created_date',
      MEMBER_SMS_CAMPAIGN_HISTORY : 'v1/insight/subscribers/campaigns/report/?campaign_type=sms&limit=$page_limit$&offset=0&ordering=-created_date',
      MEMBER_WHATSAPP_CAMPAIGN_HISTORY : 'v1/insight/subscribers/campaigns/report/?campaign_type=whatsapp&limit=$page_limit$&offset=0&ordering=-created_date'
    },

    QUERY_PARAMS : {
        PAYMENT_REQUEST_ID : '?payment_request_id='
    },

    LEAD_TAB_TYPE : {
        ALL_LEADS : 'all',
        PENDING_LEADS : 'pending',
        TODAYS_FOOLOWUP_LEADS : 'followups',
        NEW_LEADS : 'new',
        CREATE_LEAD : 'create'
    },

    LEAD_STATUS : {
        COLD : 'Cold',
        HOT : 'Hot',
        WARM : 'Warm',
        CLOSED : 'Closed',
        CONVERTED : 'Converted'
    },


    CARD_STATUS : {
        POINTS : 'Points',
        TRANSACTION : 'Transaction',
        WALLET : 'Wallet',       
        TX_FLAT : 'Flat' ,
        TX_PERCENTAGE : 'Percentage' 
    },

    ACCOUNT_MODE_STATUS : {
        CASH : 'Cash',
        CHEQUE : 'Cheque',
        CARD : 'Card',
        GOOGLEPAY : 'GooglePay',
        BHIM : 'BHIM',
        OTHERS : 'Others',
    },

    ACCOUNT_MODE_STATUS_VALUE : {
        CASH : 0,
        CARD : 1,
        CHEQUE : 2,        
        GOOGLEPAY : 3,
        BHIM : 4,
        OTHERS : 5,
    },
    
    ACCOUNT_BALANCE_CHART_MONTHS : {
        JAN : 1,
        FEB : 2,
        MAR : 3,        
        APR : 4,
        MAY : 5,
        JUN : 6,
        JUL : 7,
        AUG : 8,
        SEP : 9,
        OCT : 10,
        NOV : 11,
        DEC : 12
    },

    ACCOUNT_BALANCE_CHART_MONTHS_LABEL : {
        JAN : "Jan",
        FEB : "Feb",
        MAR : "Mar",        
        APR : "Apr",
        MAY : "May",
        JUN : "Jun",
        JUL : "Jul",
        AUG : "Aug",
        SEP : "Sep",
        OCT : "Oct",
        NOV : "Nov",
        DEC : "Dec"
    },

    

    CAMPAIGN_TYPE : {
        EMAIL : 'Email',
        SMS : 'SMS',
        WHATSAPP : 'WhatsApp',
        MOBILE : 'Mobile'
    },

    DOWNLOAD_CSV : {
        HEADERS : [
            { label: "Name", key: "name" },
            { label: "Mobile Number", key: "mobile" },
            { label: "Email", key: "email" },
            { label: "Notes", key: "contact_detail" },
            { label: "Inquiry", key: "inquiryfor" },
            { label: "Status", key: "status" },
            { label: "Assigned To", key: "assigned_to" },
            { label: "Tags", key: "tags" },
            { label: "Lead Source", key: "lead_source" },
            { label: "Lead Date", key: "lead_date" },
            { label: "Next Follow Up Date", key: "nextfollowupdate" }
        ],
        ACCOUNT_EXPENSE_HEADERS : [
            { label: "Name", key: "name" },
            { label: "Created Date", key: "created_date" },
            { label: "Amount", key: "amount" },
            { label: "Payment Mode", key: "payment_mode" },
            { label: "Notes", key: "notes" }            
        ],
        MEMBER_HEADERS : [
            { label: "First Name", key: "first_name" },
            { label: "Last Name", key: "last_name" },
            { label: "Mobile Number", key: "mobile" },
            { label: "Whatsapp Number", key: "alt_mobile" },
            { label: "Email", key: "email" },
            { label: "Joining Date", key: "joining_date" },
            { label: "Note", key: "area" }
        ],
        CAMPAIGN_HISTORY_EMAIL : [
            { label: "Template Name", key: "name" },
            { label: "Email Id", key: "email" },
            { label: "Count", key: "count" }
        ],
        CAMPAIGN_HISTORY_SMS : [
            { label: "Template Name", key: "name" },
            { label: "Mobile Number", key: "mobile" },
            { label: "Delivered", key: "delivered" },
            { label: "Status", key: "status" },
            { label: "Count", key: "count" }
        ],
        CAMPAIGN_HISTORY_WHATSAPP : [
            { label: "Template Name", key: "name" },
            { label: "Whatsapp Number", key: "mobile" },
            { label: "Delivered", key: "delivered" }
        ]
    },

    leadType : {
        ALL: 'all',
        NEW: 'new',
        FOLLOWUPS: 'followups',
        PENDING: 'pending',
    },

    accountingType : {
        EARNING: 'earning',
        NEW: 'new',
        FOLLOWUPS: 'followups',
        PENDING: 'pending',
    },

    ADD: 'ADD',
    DELETE: 'DELETE',
    SELECT_ALL: 'SELECT_ALL',

    BUTTON : {
        NEW_WHATSAPP_TEMPLATE : "New WhatsApp Template",
        NEW_SMS_TEMPLATE : "New SMS Template",
        NEW_MOBILE_TEMPLATE : "New Mobile Template",
        DELETE: "Delete",
        CANCEL: "Cancel",
        DUPLICATE:"Duplicate"
    },

    WHATSAPP_STUDIO_IMAGES_UNAVAILABLE: "Sorry no images to show!",

    TEMPLATE_TAB_TYPE: {
        EMAIL:'Email',
        SMS:'SMS',
        WHATSAPP:'WhatsApp',
        MOBILE:'Mobile',
    },

    EMAIL_TEMPLATE_ACTIONS: {
        SAVE: 'Save',
        UPDATE: 'Update',
        SEND_TEMPLATE: 'Send Template',
    },

    SMS_TEMPLATE_ACTIONS: {
        SAVE: 'Save',
        UPDATE: 'Update',
        SEND_TEMPLATE: 'Send Template',
    },

    WHATSAPP_TEMPLATE_ACTIONS: {
        SAVE: 'Save',
        UPDATE: 'Update',
        SEND_TEMPLATE: 'Send Template',
    },

    MOBILE_TEMPLATE_ACTIONS: {
        SAVE: 'Save',
        UPDATE: 'Update',
        SEND_TEMPLATE: 'Send Template',
    },

    NOTES: {
        HEADER : 'Call Back',
        SIGNUP_HEADER : 'Sign Up',
        NOTES : 'Notes : ',
        FORM : {
            NAME : 'Name',
            EMAIL : 'Email',
            CONTACT_NO : 'Contact No (optional)',
            COMMENTS : 'Comments (optional)',
            SUBMIT : 'Submit',
            CLOSE : 'Close',
        },
        SMS_TEMPLATE :'By default a new SMS is inactive and cannot be used. A Twistoe adminstrator reviews the text and activates it.',
        MOBILE_TEMPLATE :'Mobile templates created would be available for use only on Mobile App.',
        CALL_BACK: 'Please fill the form below and our team will call you back',
        SIGNUP: 'Please provide your email address to create your account!'
    },

    VALIDATION :{
      FOLLOWUP_DATE : 'Date is required.',
      FOLLOWUP_TIME : 'Time is required.',
      COMMENTS: 'Comments are required.',
      EMAIL: 'Email is required.',
      INVALID_EMAIL: 'Please enter valid email address.',
      PASSWORD: 'Password is required.',
    },

    LANDING_PAGE_CONSTANTS : {
        NAVIGATION : {
            WHY_TWISTOE : 'Why Twistoe',
            HOW_IT_WORKS : 'How it works',
            SOLUTIONS : 'Solutions',
            PRICING_PLANS : 'Pricing plans',
            TESTIMONIALS : 'Testimonials',
            SIGN_IN : 'Sign in',
        },
        CONTENT : {
            MAIN_HEADER : 'Track memberships hassle free',
            SECONDARY_HEADER : 'Designed for Yoga & Dance Studio',
            PARAGRAPH : "Whether it is winning more sales, lead engagement, managing customers & campaigning, we at <br/>TWISTOE provide innovative & efficient solutions to save your time, simplify your work & structure your business.",
            CALLBACK_BUTTON : 'Call Back',
            SIGNUP_BUTTON : 'Sign Up',
            WATCH_VIDEO_BUTTON : 'Watch Video',
            YOUTUBE_VIDEO_LINK : 'https://www.youtube.com/watch?v=YykjpeuMNEk&list=RDYykjpeuMNEk&start_radio=1',
        },
        TAGS : {
            TAGS_BENNER : 'Why Twistoe ?',
            TAGS_FEATURE : 'because you get...',
            FOLLOW_UP : 'Follow-up lead',
            CAMPAIGINING : 'Campaigning',
            INSIGHTS : 'Insights',
            TAG_LEADS : 'Tag Leads',
            CHARTING : 'Charting',
            EMAIL : 'Email',
            WHATSAPP : 'Whatsapp',
            SMS : 'SMS',
            TRACK_SALES_EFFICIENCY : 'Track Sales Efficiency',
            FILTER_PROSPECTS : 'Filter prospects',
            ARTIFICIAL_INTELLIGENCE : 'Artificial Intelligence',
        },
        TWISTOE_PROCESS : {
            BANNER: 'How it Works?',
            SUBTITLE : 'Manage Leads & Members with ease',
            CREATE_AND_MANAGE : {
                TITLE : 'Create & Manage Lead',
                PARAGRAPH : 'Gone are the days to use tedious Excel sheets & spreadsheets,though they are good at what they supposed to do, but they don\'t automate your systems and neither do they reduce your cognitive load. ' +
                    'To expand & grow your business Twistoe works as your efficient manager ! <br/><br/>' +
                    'With Twistoe, you will never miss any leads & instead you will turn your leads into your loyal customers. It easily tracks, qualifies, sets follow ups, and provide deep insight into lead engagement.'
            },
            CREATE_CAMPAIGNS : {
                TITLE : 'Create Campaigns',
                PARAGRAPH : 'Time is money and following up with each lead is time consuming job and loss of productive time for business. With Twistoe you can simply create rich templates based on your target audience interest and reach them instantly through email, SMS or Whatsapp.'
            },
            INSIGHTS : {
                TITLE : 'Insights',
                PARAGRAPH : 'As business owner we need to track our growth and auto correcting our path toward it. Twistoe provide deep insights into lead tracking and lead conversation. It helps you to analyse which campaigns working for business and where we need to put more focus.'
            },
            MANAGE_MEMBERSHIP : {
                TITLE : 'Manage Membership',
                PARAGRAPH : 'Maintaining the existing clientele is an aspect that is often neglected while a new business aims at converting new leads. With Twistoe, you can efficiently manage your existing members, track their membership, run campaigns to market your new services and offering.'
            },
            MANAGE_MOBILE_APP : {
                TITLE : 'Manage through Mobile app',
                PARAGRAPH : 'On vacation or driving, with Lead Tracker app, you can efficiently manage your leads and save 90% time on lead engagement.',
                PLAYSTORE_LINK : 'https://play.google.com/store/apps/details?id=com.taanzfiesta.sway&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
            }
        },
        SOLUTION_SECTION:{
            TITLE : 'Solutions',
            SUBTITLE : 'Software design for your business',
            GET_THIS_PLAN : 'Get this Plan',
            LEARN_MORE : 'Learn More',
        },
        PRICING_PLANS : {
            TITLE : 'Pricing Plans',
            SUBTITLE : 'Join our platform today and take your business to the next level',
            GET_THIS_PLAN : 'Get this Plan',
            QUARTERLY : {
                NAME : 'QUARTERLY',
                PRICE : '3,600/-',
                VALUES : {
                    VALUE1 : 'Lead management',
                    VALUE2 : 'Member management',
                    VALUE3 : 'Validity - 90 days',
                }
            },
            HALF_YEARLY : {
                NAME : 'HALF YEARLY',
                PRICE : '6,600/-',
                VALUES : {
                    VALUE1 : 'Lead management',
                    VALUE2 : 'Member management',
                    VALUE3 : 'Validity - 180 days',
                }
            },
            YEARLY : {
                NAME : 'YEARLY',
                PRICE : '10,000/-',
                VALUES : {
                    VALUE1 : 'Lead management',
                    VALUE2 : 'Member management',
                    VALUE3 : 'Validity - 365 days',
                }
            },
            PLANS_SETTINGS : {
                slidesToShow: 4,
                centerMode: false,
                variableWidth: true,
                arrows: false,
                focusOnSelect: true,
                infinite: false,
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 1366,
                        settings: {
                            slidesToShow: 4,
                            arrows: false
                        }
                    }, {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    }
                ]

            }
        },
        TESTIMONIALS : {
            TITLE : 'Testimonials',
            CLIENTS : [
                {
                    name : 'Anjali Kukreja',
                    company_name : 'The Dance Floor, Pune',
                    profile_picture : 'anjali-kukreja',
                    review : '“This is such a great studio management software. Wow every dancer and Artists needs this. Thanks to TWISTOE for understanding our needs and implementing them.”'
                },
                {
                    name : 'Sagar Girme',
                    company_name : 'On8, Pune',
                    profile_picture : 'sagar-girme',
                    review : '“Amazing concept!!! A well designed product for all dance enthusiasts and dance schools. Great way to organize and function your classes and schedule them if you have a dance school. Great job by the team!!!waiting for further releases.”'
                },
                {
                    name : 'Priyanka Kirad',
                    company_name : 'The Feet Circus, Pune',
                    profile_picture : 'priyanka-kirad',
                    review : '“I was looking for a studio management system which will simplify my management work. Just when I was in search of something like this I came across TWISTOE. And believe me its such a blessing. ' +
                        'It takes care of everything right from the students address to their whole membership details. ' +
                        'Everything feels so sorted. Thanks to TWISTOE for making things easy for me and my studio.”'
                }
            ],
            SLIDER_SETTING : {
                centerMode: true,
                infinite: true,
                slidesToShow: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                variableWidth: true,
                centerPadding: '0px',
                arrows: false,
                focusOnSelect: true,
                swipeToSlide: true,
                dots: true,
                responsive: [
                    {
                        breakpoint: 1366,
                        settings: {
                            slidesToShow: 1,
                            infinite: true,
                            dots: true,
                            arrows: false
                        }
                    }, {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false
                        }
                    }
                ]

            }
        },
        SINGUP : {
            TITLE : 'Get Started',
            SUBTITLE : 'Sign up for free to start your 1 month trial',
            BUTTON : 'Sign Up',
        },
        NEWSLETTER : {
            TITLE : 'We Would Love to Help you',
            SUBTITLE : 'We are all excited to work with you and see you growing',
            BUTTON : 'Get in Touch',
        },
        FOOTER : {
            COMPANY_NAME : '@Intertwine Technologies'
        }
    },

    HTTP_REQUESTS : {
        GET : {
            url: '',
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ''
            }
        },
        POST : {
            url: '',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ''
            },
            body : {}
        },
        PATCH : {
            url: '',
            method: 'patch',
            headers: {
                'Content-Type': 'application/json',
                Authorization: ''
            },
            body : {}
        }
    },

    SESSION_VALUES : {
        SUN : {
            value : 64,
            active : 0
        },
        SAT : {
            value : 32,
            active : 0
        },
        FRI : {
            value : 16,
            active : 0
        },
        THU : {
            value : 8,
            active : 0
        },
        WED : {
            value : 4,
            active : 0
        },
        TUE : {
            value : 2,
            active : 0
        },
        MON : {
            value : 1,
            active : 0
        },
    },

    PRODUCT_TYPE : {
        INACTIVE_PRODUCTS : 'Inactive',
        ACTIVE_PRODUCTS : 'Active',
    },

    COLOR_CODES : {
        CARD_BG : [
        '#8BC8FC',
        '#FDC78B',
        '#919BF1',
        '#D2C889',
        '#C1ACFF',
        '#FF9CD1',
        '#90D0A5',
        '#53ACE7',
        '#E49696',
        '#9FC188',
        '#E4B19A',
        '#CB90EF',
        '#E4B19A',
        '#6FC0C0',
        '#FFA1A9',
        ],
        CARD_HEADER : [
            '#5BAAEB',
            '#FAAD57',
            '#6F6CDE',
            '#BFB050',
            '#A88BFF',
            '#E55EA6',
            '#679F7A',
            '#3994D0',
            '#D66C6C',
            '#678A4E',
            '#D4997F',
            '#A864D2',
            '#D4997F',
            '#4DABAB',
            '#FD7E89',
        ]
    },

    CAMPAIGN_STATUS : {
        INITIATED : 'Inititated',
        IN_PROGRESS : 'Inititated',
        COMPLETED : 'Completed',
    }

};

export default Constants;
