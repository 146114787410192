import React from "react";

const Labels = {

    FORMS : {
        BDAY : 'Birth Day',
        EMAIL : 'Email Id',
        OPTIONAL_EMAIL : 'Email Id (optional)',
        DESCRIPTION : 'Description',
        NAME : 'Name',
        SEARACH_BY_NUMBER : 'Search customer by mobile',
        PHONE_NUMBER : 'Phone No.(e.g.+919876543210)',
        OPTIONAL_WHATSAPP_NUMBER : 'WhatsApp No. (optional)(e.g.+919876543210)',
        FIRST_NAME : 'First Name',
        LAST_NAME : 'Last Name',
        PHONE_NUMBER_MEMBER : 'Phone No.(e.g.+919876543210)',
        OPTIONAL_WHATSAPP_NUMBER_MEMBER : 'WhatsApp No. (optional)(e.g.+919876543210)',
        PASSWORD : 'Password',
        FOLLOW_UP_DATE : 'Followup Date',
        FOLLOW_UP_TIME : 'Followup Time',
        COMMENTS : 'Comments',
        DATE_VISITED_ON : 'Date visited on',
        OPTIONAL_FOLLOWUP_DATE : 'Next followup Date (optional)',
        OPTIONAL_FOLLOWUP_TIME : 'Next Followup Time (optional)',
        OPTIONAL_NOTE : 'Note (optional)',
        NOTE : 'Note',
        STATUS : 'Status',
        CARD_TYPE : 'Card Type',
        CATEGORY_SUBSCRIPTION:'Category Subscription',
        SESSION_FEES:'Session fees',
        REGISTRATION_DATE: 'Registration Date',
        AMOUNT_PAID: 'Amount Paid',
        AMOUNT_PRICE: 'Price',
        AMOUNT_TOTAL: 'Amount Total',
        PAYMENT_MODE: 'Payment Mode',
        EXPENSE_DATE : 'Expense Date',
        EXPENSE_AMOUNT : 'Expense Amount',
    },

    HEADINGS : {
        SIGN_IN : 'Sign in',
        RECHARGE : 'Recharge',
        SPECIAL_RECHARGE : 'Special Recharge',
        SUBSCRIPTION_PLANS : 'Subscription plans',
        QUICK_SALE : 'Quick Sale',
        ACCOUNTING : 'Accounting',
        MEMBERSHIP_CARDS : 'Membership Cards',
        GIFT_CARDS : 'Gift Cards',
        POS : 'Quick Sale',
        ACCOUNTING_EARNING : 'Earning',
        ACCOUNTING_BALANCE : 'Balance',
        ACCOUNTING_EXPENSE : 'Expense',
        ACCOUNTING_EDIT_EXPENSE_FORM : 'Edit Expense',
        LEADS : 'Leads',
        ALL_LEADS : 'All Leads',
        NEW_LEADS : 'New Leads',
        TODAYS_FOLLOWUP_LEADS : 'Today\'s Follow-ups',
        PENDING_FOLLOWUP_LEADS : 'Pending Follow-ups',
        NEW_EXPENSE_FORM : 'New Expense',
        NEW_LEAD_FORM : 'New Lead',
        NEW_CARD_FORM : 'New Card',
        EDIT_CARD_FORM : 'Edit Card',
        EDIT_LEAD_FORM : 'Edit Lead',
        EDIT_CARD_FORM : 'Edit Card',
        EDIT_MULTIPLE_SELECTED_LEADS : 'Edit Selected Leads',
        MEMBER_MANAGEMENT : 'Member Management',
        CAMPAIGN_HISTORY : 'Campaign History',
        SALES_INSIGHTS : 'Sales Insights',
        MEMBER_PRODUCTS : 'Products',
        PRIMARY_CONTACT : 'Primary Contact',
        RECHARGE_PLAN: 'Recharge plan',
        SIGN_OUT: 'Sign Out',
        PRODUCT_OFFERING_DETAILS:'Product Offering Details',
        PRODUCT_OFFERING_DETAILS_SUBSCRIBE:'Add New Subscription',
        PRODUCT_OFFERING_DETAILS_RENEW:'Renew Subscription',
        PRODUCT_OFFERING_DETAILS_EDIT :'Edit Subscription',
        PRODUCT_OFFERING_HISTORY :'Subscription history of $user_name$',
        NEW_MEMBER:'New Member',
        CONVERT_MEMBER:'Convert Lead to Member',
        START_DATE: 'Start Date',
        END_DATE: 'End Date',
        EXTENDED_DATE_OPTIONAL:' Extended Date(optional)',
        EXTENDED_DATE:' Extended Date',
        SUBSCRIPTIONS:'Subscriptions',
        EDIT_EXPENSE_FORM : 'Edit Expense',
    },

    SUBHEADINGS : {
        MULTIPLE_SELECT_PLANS : '(You can select multiple plans from below)',
        SELECT_SUBSCRIPTION_PLAN : '(You can select any one plan from below) ',
        SUBSCRIPTION_PLAN_END_DATE : 'Your Subscription is ending on ',
        JOB_DETAILS : 'Job Details ',
    },

    ACTION_BUTTONS : {
        SIGN_IN : 'Sign in',
        ADD_NOW : 'RENEW NOW',
        PAY : 'Proceed to Pay ',
        CANCEL : 'Cancel',
        SAVE : 'Save',
        CREATE_LEAD : 'Create Lead',
        UPDATE_LEAD : 'Update Lead',
        CREATE_CARD : 'Create Card',
        ADD_SERVICE : 'Add Service',
        ADD_PACKAGE : 'Add Package',
        UPDATE_CARD : 'Update Card',
        CREATE_PRODUCT : 'Create Product',
        UPDATE_PRODUCT : 'Update Product',
        BACK:'Back',
        CONVERT_MEMBER:'Convert to Member',
        ADD_MEMBER: 'Save and Next',
        EDIT_MEMBER: 'Update',
        ADD_OFFERING: 'Add offering',
        UPDATE_OFFERING: 'Update offering',
        EDIT_SUBSCRIPTION:'Edit Offering',
        DEACTIVATE_SUBSCRIPTION:'Deactivate Offering',
        ACTIVATE_SUBSCRIPTION:'Activate Offering',
        DELETE_SUBSCRIPTION:'Delete Offering',
        SUBSCRIBE:'Subscribe',
        RENEW:'Renew',
        CONTINUE: 'Continue',
        DELETE: 'Delete',
        DEACTIVATE: 'Deactivate',
        CONVERT: 'Convert',
        UPDATE_EXPENSE: 'Update Expense',
        CREATE_EXPENSE: 'Create Expense',
        SEARCH: 'Search',
    },

    LEAD_TAB_TYPE : {
        ALL_LEADS : 'all',
        PENDING_LEADS : 'pending',
        TODAYS_FOOLOWUP_LEADS : 'followups',
        NEW_LEADS : 'new',
        CREATE_LEAD : 'create'
    },

    ACCOUNTING_TAB_TYPE : {
        EARNING : 'earning',
        EXPENSE : 'expense',
        BALANCE : 'balance',
        TODAYS_FOOLOWUP_LEADS : 'followups',
        NEW_LEADS : 'new',
        CREATE_LEAD : 'create',
        AVG_EARNING : 'Earning',
        AVG_EXPENSE : 'Expenses',
        AVG_BALANCE : 'Balance',
    },

    ADD: 'ADD',
    DELETE: 'DELETE',
    SELECT_ALL: 'SELECT_ALL',

    BUTTON : {
        NEW_WHATSAPP_TEMPLATE : "New WhatsApp Template",
        NEW_SMS_TEMPLATE : "New SMS Template",
        NEW_MOBILE_TEMPLATE : "New Mobile Template",
        DELETE: "Delete",
        CANCEL: "Cancel",
        DUPLICATE:"Duplicate"
    },

    TEMPLATE_TAB_TYPE: {
        EMAIL:'Email',
        SMS:'SMS',
        WHATSAPP:'WhatsApp',
        MOBILE:'Mobile',
    },

    EMAIL_TEMPLATE_ACTIONS: {
        SAVE: 'Save',
        UPDATE: 'Update',
        SEND_TEMPLATE: 'Send Template',
    },

    SMS_TEMPLATE:{
        PROMOTIONAL: 'Promotional',
        TRANSACTIONAL:'Transactional'
    },

    SMS_TEMPLATE_ACTIONS: {
        SAVE: 'Save',
        UPDATE: 'Update',
        SEND_TEMPLATE: 'Send Template',
    },

    MOBILE_TEMPLATE_ACTIONS: {
        SAVE: 'Save',
        UPDATE: 'Update',
        SEND_TEMPLATE: 'Send Template',
    },

    NOTES: {
        HEADER : 'Call Back',
        NOTES : 'Notes : ',
        FORM : {
            NAME : 'Name',
            EMAIL : 'Email',
            CONTACT_NO : 'Contact No (optional)',
            COMMENTS : 'Comments (optional)',
            SUBMIT : 'Submit',
            CLOSE : 'Close',
        },
        SMS_TEMPLATE :'By default a new SMS is inactive and cannot be used. A Twistoe adminstrator reviews the text and activates it.',
        MOBILE_TEMPLATE :'Mobile templates created would be available for use only on Mobile App.',
        CALL_BACK: 'Please fill the form below and our team will call you back'
    },

    CAMPAIGNS:{
        EDIT: 'Edit',
        DUPLICATE:'Duplicate',
        DELETE:'Delete',
        PREVIEW:'Preview',
        PACKAGE_HISTORY:'View package history',
        LEADS_CAMPAIGN:'Leads Campaign',
        NO_TEMPLATES:'No templates found',
        SMS_NOTE:'Note:',
        SMS_NOTE_DESCRIPTION:' Only active templates are listed here and can be sent as SMS.',
        NO_ACTIVE_TEMPLATES :'No active templates found.',
        ONLY_ACTIVE:    'Only active templates are listed here and can be sent as SMS.',
        HELP: '<b>Personalize your Twistoe campaign content.</b> <p class="tooltips">To get personally engaged' +
            ' with your customers in your SMS, Email or Whatsapp campaigns you can personalize them using their contact property. To show' +
            ' actual value of your contact use contact property as shown below: <br/> username - @username<br/> mobile - @mobile <br/> email' +
            ' - @email </p>',
    },

    VALIDATION :{
        FOLLOWUP_DATE : 'Date is required.',
        FOLLOWUP_TIME : 'Time is required.',
        COMMENTS: 'Comment is required.',
        EMAIL: 'Email is required.',
        NAME: 'Name is required.',
        FIRST_NAME: ' First name is required.',
        LAST_NAME: 'Last name is required.',
        SESSION_FEE: 'Session fees is required.',
        SUBSCRIPTION_NAME: 'Subscription name is required.',
        SUBSCRIPTION_LABEL: 'Subscription label is required.',
        NO_OF_SESSIONS: 'Number of sessions is required.',
        PHONE_NUMBER: 'Phone number is required.',
        PHONE_NUMBER_EMAIL: 'Phone number or email id is required.',
        CARD_TYPE: 'Card type required.',
        CARD_TYPE_AMOUNT: 'Amount Required.',
        CARD_TYPE_VALIDTY: 'Validity Required.',
        CARD_TYPE_REQUIRED: 'Required.',
        CARD_TYPE_POINT_AMOUNT: 'Point amount required.',
        CARD_TYPE_POINT_REWARDS: 'Rewards amount required.',
        CARD_TYPE_POINT_MINIMUM: 'Minimum points to redeem required.',
        PASSWORD: 'Password is required.',
        STATUS: 'Status is required.',
        PAYMENT_MODE: 'Payment mode is required.',
        TEMPLATE_NAME:"Template name is required.",
        SUBJECT:'Subject  is required.',
        DESCRIPTION:"Description  is required.",
        SMS_LIMIT: "SMS character count limit of 1000 exceeded.",
        IMAGE_URL:"Please enter valid image url.",
        IMAGE_URL_LIMIT: "URL character count limit of 160 exceeded.",
        DATE_VISITED: 'Date visited on is required.',
        INVALID_EMAIL: 'Please enter valid email address.',
        INVALID_MOBILE_NUMBER: 'Please enter valid 10 digit mobile no.',
        INVALID_WHATSAPP_NUMBER: 'Please enter valid 10 digit  whatsapp no.',
        INVALID_MOBILE: 'Please enter valid mobile no.',
        INVALID_MOBILE_COUNTRY_CODE: 'Please enter country code',
        INVALID_FOLLOW_UP_TIME: 'Please enter follow-up time.',
        INVALID_FOLLOW_UP_DATE: 'Please enter follow-up date.',
        INVALID_NAME_NO: 'Only letters and numbers allowed.',
        INVALID_NAME: 'Only letters allowed.',
        INVALID_SESSION: 'Enter valid number of sessions.',
        CHARACTER_LIMIT_128: 'Character count limit of 128 exceeded.',
        CHARACTER_LIMIT_256: 'Character count limit of 256 exceeded.',
        SESSION_FEE_LIMIT: 'Session fee limit exceeded.',
        NO_OF_SESSIONS_LIMIT_2147483647: 'No of sessions limit exceeded.',
        INVALID_SESSION_FREQUENCY: 'Select a type.',
        CONDUCT_DAYS: 'Select days you want to conduct the sessions.',
        INVALID_DETAILS: "Please enter valid details.",
        CREATE_DATE_TO: 'Please enter To date.',
        EXPIRY_FROM: 'Please enter From date.',
        FOLLOWUP_DATE_TO: 'Please enter To date.',
        EDIT_MULTIPLE_SELECTED_LEADS_SELECT_SOME: 'Please select/add some field.',
        DATE:'Please enter the date.',
        NUMBER:'Please enter numbers only',
        SERVICES:'Please select services',
        CUSTOMER:'Please select a customer',
        AMOUNT_PAID:'Amount paid is required.',
        AMOUNT_INVALID:'Amount paid cannot be more than session fees.',
        NOTES:"Note is required.",
        PRODUCT_NAME_IS_REQUIRED: 'Product name is required.',
        EXPENSE_AMOUNT: "Expense required.",
        EXPENSE_AMOUNT_NUMERIC: "Expense should be a positive numeric value.",
        EXPENSE_VISITED: 'Expense Date is required.',
        EXPENSE_NOTES: 'Notes is required.',
        EXPENSE_PAYMENT_MODE: 'Please select Payment mode.'
    },

    EXPENSE_LIST:{
        NOTE:'Notes',
        AMOUNT:'Amount',
        NAME:'Name',
        PAYMENT_MODE:'Payment Mode',
    },

    EXPENSE_EARNINING_LIST:{
        EEARNING_DATE_RANGE:'Select Date Range',    
        FILTER_AND_SEARCH: 'Apply Filter',    
    },

    CARD_LIST:{
        NAME:'Title',
        DATE: 'Date',
        VALIDTY:'Validity (Days)',
    },

    LEAD_LIST:{
        SELECT_CURRENT:'Select Current Page',
        SELECT_ALL:'Select All Leads',
        CREATED: 'Created',
        DATE: 'Date',
        NAME:'Name &',
        CONTACT:'Contact',
        TAGS:'Tags',
        SOURCE:'Source',
        NOTE:'Note',
        ASSIGNED_TO:'Assigned To',
        FOLLOW_UP_ON:'Follow-Up On',
        FOLLOW_UP:'Follow-Up ',
        DATE_TIME:'Date & Time',
        ADD_FOLLOW_UP:'  Add Follow-up',
        FOLLOW_UP_OF:'Follow up Of',
        NEXT_FOLLOW_UP_DATE:' Next followup Date',
        NEXT_FOLLOW_UP_TIME:' Next followup Time',
        NO_FOLLOW_UP_HISTORY:'There is no follow-up History',
        COMMENTS:'Comments',
        HISTORY:'History',
        SELECTED_LEAD_LIST:'Selected Lead List'
    },

    MEMBER_LIST: {
        SELECT_CURRENT: 'Select Current Page',
        SELECT_ALL: 'Select All Members',
        SELECTED_MEMBER_LIST:'Selected Member List'
    },

    EMPTY_LEADS:{
        NO_FILTER_DATA:'There is no filter and search data available for the above search.',
        NO_CREATED_LEAD:'It seems you\'ve not created any lead.',
        CREATE_NEW_LEAD:'Create New Leads and take follow up, send campaigns, watch insight.',
        NO_PENDING_FOLLOW_UPS:' It seems you dont have any pending followups.',
        NO_TODAYS_FOLLOWUPS:' It seems you don\'t have any followups for today.',
        NO_SELECTED_LEADS:'It seems you have not selected any lead.',
        NO_CARD_PRESENTS:'It seems you have not created any cards.'
    },

    EMPTY_MEMBERS:{
        NO_FILTER_DATA:'There is no filter and search data available for the above search.',
        NO_CREATED:'It seems you\'ve not any Members.'
    },

    LEADS_FILTER: {
        FOLLOW_UP: 'Follow-up Date',
        CREATED_DATE: 'Created Date',
        TODAY: 'Today',
        TOMORROW:'Tomorrow',
        NEXT_7_DAYS: 'Next 7 Days',
        CUSTOM:'Custom',
        FILTER_AND_SEARCH: 'Filter and search',
        CHOOSE_CUSTOM_DATE:'or Choose Custom Date',
        FROM:'From',
        TO: 'To',
        TAGS: 'Tags',
        APPLY: 'Apply',
        CANCEL: 'Cancel'
    },

    EXPENSE_APPLIED_FILTER:{
        NAME: 'Name :',
        AMOUNT: 'Amount :',
        NOTES: 'Notes :',
        PAYMENT_MODE: 'Payment Mode :',
        MOBILE:'Mobile :',
        CREATED_DATE:'Created Date :',
        FOLLOW_UP_DATE: 'Follow-up Date :',
        TAGS: 'Tags :',
        ASSIGNED_TO:'Assigned To :',
        CLEAR_ALL: 'Clear all',
        NO_FILTER_APPLIED: 'No Filter Applied'
    },

    APPLIED_FILTER:{
        NAME: 'Name :',
        MOBILE:'Mobile :',
        CREATED_DATE:'Created Date :',
        FOLLOW_UP_DATE: 'Follow-up Date :',
        TAGS: 'Tags :',
        ASSIGNED_TO:'Assigned To :',
        STATUS:'Status :',
        CLEAR_ALL: 'Clear all',
        NO_FILTER_APPLIED: 'No Filter Applied'
    },

    PACKAGE_HISTORY: {
        VIEW_HISTORY:'View History',
        NO_HISTORY:'There is no Package History',
        // SUBSCRIPTION_PLAN: 'Subscription Plan'
    },

    LEFT_DRAWER_LEAD_MANAGEMENT:{
        LEAD_MANAGEMENT: 'Lead Management',
        MEMBER_MANAGEMENT: 'Member Management',
        ACCOUNTING_MANAGEMENT: 'Accounting',        
        QUICK_SALE: 'Quick Sale',
        NO_NOTIFICATIONS: 'No notification to display'
    },

    EMPTY_INSIGHT_DATA:{
        NO_OVERVIEW: 'Insight - Data not available',
        NO_OVERVIEW_DETAIL:'Sorry, no result found for selected dates, try searching by selecting different dates'
    },

    COMPARE_TAGS:{
        PLUS: '+',
        NO_TAGS: 'No tags available'
    },

    STATISTICS:{
        INSIGHT:'Leads Insight',
        OVERVIEW: 'Overview',
        DISTRIBUTION:'Distribution',
        TAG_TRENDS:'Tag Trends'
    },

    CONVERTED_TO_LEADS:{
        USER_CONVERTED:'User Converted Leads'
    },

    LEAD_CONVERTED_AND_CAMPAIGNS:{
        CONVERTED:'Lead converted & Campaigns'
    },

    LEADS_STATISTICS_COMPARISON:{
        GENERATION:'Leads generation ',
        COMPARED:' as compared to previous period',
        CONVERSION: 'Leads conversion ',
        CURRENT: 'for current period',
        LEADS_GENERATED: 'Leads Generated',
        AVG_LEADS_GENERATED:'Avg. Leads Generated',
        LEADS_CONVERTED: 'Leads Converted',
        AVERAGE_LEADS_CONVERTED:'Avg. Leads converted/day',
        LEADS_WON:'Leads Won' ,
        LEADS_LOST:'Leads Lost',
        CURRENT_PERIOD:'Current Period',
        PREVIOUS_PERIOD:'Previous Period',
        NO_STATS_DATA: 'No data available for lead statics',
    },

    TOP_5_TAGS: 'Top 5 Tags',
    TOP_5_CATEGORY_SALES: 'Top 5 Category Sales',
    COMPARE_OFFERING_SALES: 'Compare Offering Sales',
    TOTAL_SALES:'Total Sales',

    MEMBER_MGMT_DASHBOARD : {
        ALL : 'All',
        ACTIVE : 'Active',
        EXPIRED : 'Expired',
        MEMBERS_WITH_SUBSCRIPTIONS : 'Pending',
        MEMBERS_WITHOUT_SUBSCRIPTIONS : 'Pending',
        VIEW_ALL:'View all',
        VIEW_ALL_EXPIRED:'View all expired',
    },

    PRODUCT_TYPES : {
        ACTIVE : 'Active',
        INACTIVE : 'Inactive'
    },

    MEMBER_ACTIONS : {
      EDIT_MEMBER : 'Edit Member',
      VIEW_HISTORY : 'View History',
      SUBSCRIBE : 'Add Subscription',
      DELETE_MEMBER : 'Delete Member',
    },

    MEMBER_FILTER : {
        OFFERING_EXPIRY: 'Offering Expiry'
    },


    CAMPAIGN_HISTORY : {
        EMAIL : 'Email',
        SMS : 'SMS',
        WHATSAPP : 'Whatsapp',

        TABLE_HEADERS : {
            CAMPAIGN : 'Campaign',
            DATE : 'Date',
            DATE_AND_TIME : 'Date & Time',
            STATUS : 'Current Status',
            DOWNLOAD : 'Download',
            DOWNLOAD_REPORT_BUTTON : 'Click to Download'
        },
        EMPTY_REPORT_DATA : 'No Campaign history is available.'
    },

    PRODUCT_SUBSCRIPTION:{
        SUBSCRIPTION_NAME: 'Subscription Name',
        SUBSCRIPTION_LABEL: 'Subscription Label',
        NO_SESSIONS:'Number of sessions',
        SESSION_FEES:'Session fees',
        ONETIME:'Onetime',
        CONTINUOUS:'Continuous',
        START_DATE:'Start Date',
        END_DATE:'End Date'
    },

    CONVERT_LEAD_TO_MEMBER_CONFIRMATION: 'The lead "$name$" will be converted to a member and no longer available in the leads listing.',
    CONVERT_LEAD_TO_MEMBER_INSTRUCTION: 'Please click on continue button if you want to convert the lead "$lead_name$" to the member, and to cancel the whole operation click on cancel button',

    DELETE_CONFIRMATION_MESSAGE: 'Do you want to $deleteType$ "$name$"?'

};

export default Labels;
