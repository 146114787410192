const initialState = {
    type: 'LAYOUT_LOADER',
    startLayOutLoader : 'false'
};

const layoutLoader = (state = initialState, action) => {
    switch (action.type) {
        case 'LAYOUT_LOADER' : return action;
        default : return state
    }
};

export default layoutLoader;