import Constants from '../../Constants/Constants';

export const setToasterNotification = response => {
    return dispatch => {
        dispatch({type: 'SET_FLAG',
            responseType : response.responseType,
            message : response.message});
    }
};

export const hideToasterNotification = () => ({
    type: 'SET_FLAG',
    responseType : Constants.RESPONSE_TYPE_NOTHING,
    message : 'toaster hide',
});

export const startLoader = response => ({
    type : 'LOADER',
    startLoader : response
});

export const startLayOutLoader = respnse => ({
    type : 'LAYOUT_LOADER',
    startLayOutLoader : respnse
});

export const paginationData = response => ({
    type : 'PAGINATION_DATA',
    pagination : response
});

export const setMemberPaginationData = response => ({
    type : 'MEMBER_PAGINATION_DATA',
    pagination : response
});

export const setAllLeadsPaginationDetail = data => ({
    type : 'ALL_LEADS',
    currentURL : data.currentURL
});

export const setAllEarningPaginationDetail = data => ({
    type : 'ALL_EARNING',
    currentURL : data.currentURL
});

export const newLeadsPaginationDetails = data => ({
    type : 'NEW_LEADS',
    currentURL : data.currentURL
});

export const pendingLeadsPaginationDetail = data => ({
    type : 'PENDING_LEADS',
    currentURL : data.currentURL
});

export const todaysFollowUpsPaginationDetail = data => ({
    type : 'TODAYS_FOLLOWUPS',
    currentURL : data.currentURL
});

export const allMembersTab = data => ({
    type : 'ALL_MEMBERS',
    currentURL : data.currentURL
});

export const activeMembersTab = data => ({
    type : 'ACTIVE_MEMBERS',
    currentURL : data.currentURL
});

export const expiredMembersTab = data => ({
    type : 'EXPIRED_MEMBERS',
    currentURL : data.currentURL
});

export const membersWithSubscriptionTab = data => ({
    type : 'MEMBERS_WITH_SUBSCRIPTION',
    currentURL : data.currentURL
});

export const membersWithoutSubscriptionTab = data => ({
    type : 'MEMBERS_WITHOUT_SUBSCRIPTION',
    currentURL : data.currentURL
});

export const setLeadsOverviewFilter = data => ({
    type : 'LEADS_OVERVIEW_FILTER',
    currentFilter : data.currentFilter
});

export const setLeadsDistributionFilter = data => ({
    type : 'LEADS_DISTRIBUTION_FILTER',
    currentFilter : data.currentFilter
});

export const setLeadsTagTrendsFilter = data => ({
    type : 'LEADS_TAG_TRENDS',
    currentFilter : data.currentFilter
});

export const CLEAR_SELECT_TAGS = 'CLEAR_SELECT_TAGS';

export const clearTagsFromSelectTags = (flag) => ({
        type: CLEAR_SELECT_TAGS,
        isClearSelectedTags: flag
});

export const tabsLeadCount = data => ({
    type : 'TAB_LEADS_COUNT',
    data : data
});

export const updateLeadTabsCount = flag => ({
    type : 'UPDATE_LEAD_TABS_COUNT',
    flag : flag
});

export const updateAccountExpenses = flag => ({
    type : 'UPDATE_ACCOUNT_EXPENSES',
    flag : flag
});
export const updateMemberTabsCount = flag => ({
    type : 'UPDATE_MEMBER_TABS_COUNT',
    flag : flag
});

export const clearAssignedTo = (flag) => ({
   type:'CLEAR_ASSIGNED_TO',
    isClearAssignedTo: flag
});

export const clearStatus = (flag) => ({
    type:'CLEAR_STATUS',
     isClearStatus: flag
 });

