export const ADD_LEAD_TO_ALL_LEADS = 'ADD_LEAD_TO_ALL_LEADS';
export const ADD_LEAD_TO_NEW_LEADS = 'ADD_LEAD_TO_NEW_LEADS';
export const ADD_LEAD_TO_TODAY_FOLLOW_UPS = 'ADD_LEAD_TO_TODAY_FOLLOW_UPS';
export const ADD_LEAD_TO_PENDING_FOLLOW_UPS = 'ADD_LEAD_TO_PENDING_FOLLOW_UPS';

export const DELETE_LEAD_FROM_ALL_LEADS = 'DELETE_LEAD_FROM_ALL_LEADS';
export const DELETE_LEAD_FROM_NEW_LEADS = 'DELETE_LEAD_FROM_NEW_LEADS';
export const DELETE_LEAD_FROM_TODAY_FOLLOW_UPS = 'DELETE_LEAD_FROM_TODAY_FOLLOW_UPS';
export const DELETE_LEAD_FROM_PENDING_FOLLOW_UPS = 'DELETE_LEAD_FROM_PENDING_FOLLOW_UPS';

export const CHECK_CURRENT_PAGE_SELECT_ALL = 'CHECK_CURRENT_PAGE_SELECT_ALL';
export const UNCHECK_CURRENT_PAGE_SELECT_ALL = 'UNCHECK_CURRENT_PAGE_SELECT_ALL';

export const CHECK_SELECT_ALL_LEADS = 'CHECK_SELECT_ALL_LEADS';
export const UNCHECK_SELECT_ALL_LEADS = 'UNCHECK_SELECT_ALL_LEADS';

export const REMOVE_SELECTED_LEADS = 'REMOVE_SELECTED_LEADS';


export const addLeadToAllLeads = (selectedLead) => (
    {type: ADD_LEAD_TO_ALL_LEADS, selectedLead: selectedLead,}
);

export const addLeadToNewLeads = (selectedLead) => (
    {type: ADD_LEAD_TO_NEW_LEADS, selectedLead: selectedLead,}
);


export const addLeadToTodayFollowUps = (selectedLead) => (
    {type: ADD_LEAD_TO_TODAY_FOLLOW_UPS, selectedLead: selectedLead,}
);


export const addLeadToPendingFollowups = (selectedLead) => (
    {type: ADD_LEAD_TO_PENDING_FOLLOW_UPS, selectedLead: selectedLead,}
);


export const deleteLeadFromAllLeads = (selectedLead) => (
    {type: DELETE_LEAD_FROM_ALL_LEADS, selectedLead: selectedLead,}
);

export const deleteLeadFromNewLeads = (selectedLead) => (
    {type: DELETE_LEAD_FROM_NEW_LEADS, selectedLead: selectedLead,});


export const deleteLeadFromTodayFollowUps = (selectedLead) => (
    {type: DELETE_LEAD_FROM_TODAY_FOLLOW_UPS, selectedLead: selectedLead,});


export const deleteLeadFromPendingFollowups = (selectedLead) => (
    {type: DELETE_LEAD_FROM_PENDING_FOLLOW_UPS, selectedLead: selectedLead,}
);

export const checkCurrentPageSelectAll = () => (
    {type: CHECK_CURRENT_PAGE_SELECT_ALL}
);

export const unCheckCurrentPageSelectAll = () => (
    {type: UNCHECK_CURRENT_PAGE_SELECT_ALL}
);

export const checkSelectAllLeads = (data) => (
    {type: CHECK_SELECT_ALL_LEADS, currentURL : data.currentURL, currentTab: data.currentTab}
);

export const uncheckSelectAllLeads = () => (
    {type: UNCHECK_SELECT_ALL_LEADS}
);

export const removeSelectedLeads = () => (
    {type: REMOVE_SELECTED_LEADS}
);










