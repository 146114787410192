const initialState = {
    type:'CLEAR_ASSIGNED_TO',
    isClearAssignedTo: false
};

const selectAssignedTo = (state = initialState, action) => {
    return {
        ...state,
        isClearAssignedTo:action.isClearAssignedTo
    }
};

export default selectAssignedTo;