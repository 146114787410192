const initialState = {
    type: 'ACCOUNTING_BALANCE_FILTER',
    currentFilter : 'This Month'
};

const accountBalance = (state = initialState, action) => {
    switch (action.type) {
        case 'ACCOUNTING_BALANCE_FILTER' : return action;
        default : return state;
    }
};

export default accountBalance;