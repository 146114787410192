const initialState = {
    type: 'LOADER',
    startLoader : 'false'
};

const loader = (state = initialState, action) => {
    switch (action.type) {
        case 'LOADER' : return action;
        default : return state
    }
};

export default loader;