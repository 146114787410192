import * as actions from '../Actions/membersListAction';

const initialState = {
    allMembers: {},
    activeMembers: {},
    expiredMembers: {},
    membersWithSubscription: {},
    membersWithoutSubscription: {},
    isCurrentPageSelected: false,
    selectAllMembers: { isSelectAll: false, currentURL : '', currentTab :''}
};

const selectedMembers = (state = initialState, action) => {
    switch (action.type) {

        case actions.ADD_MEMBER_TO_ALL_MEMBERS :
            return {
                ...state,
                allMembers:{
                    ...state.allMembers,
                    [action.selectedMember.id]: action.selectedMember
                }
            };

        case actions.DELETE_MEMBER_FROM_ALL_MEMBERS :
            return  {
                ...state,
                allMembers:{
                    ...state.allMembers,
                    [action.selectedMember.id]: null
                }
            };

        case actions.ADD_MEMBER_TO_ACTIVE_MEMBERS :
            return {
                ...state,
                activeMembers:{
                    ...state.activeMembers,
                    [action.selectedMember.id]: action.selectedMember
                }
            };

        case actions.DELETE_MEMBER_FROM_ACTIVE_MEMBERS :
            return  {
                ...state,
                activeMembers:{
                    ...state.activeMembers,
                    [action.selectedMember.id]: null
                }
            };

        case actions.ADD_MEMBER_TO_EXPIRED_MEMBERS :
            return {
                ...state,
                expiredMembers:{
                    ...state.expiredMembers,
                    [action.selectedMember.id]: action.selectedMember
                }
            };

        case actions.DELETE_MEMBER_FROM_EXPIRED_MEMBERS :
            return  {
                ...state,
                expiredMembers:{
                    ...state.expiredMembers,
                    [action.selectedMember.id]: null
                }
            };

        case actions.ADD_MEMBER_WITH_SUBSCRIPTION :
            return {
                ...state,
                membersWithSubscription:{
                    ...state.membersWithSubscription,
                    [action.selectedMember.id]: action.selectedMember
                }
            };

        case actions.DELETE_MEMBER_WITH_SUBSCRIPTION :
            return  {
                ...state,
                membersWithSubscription:{
                    ...state.membersWithSubscription,
                    [action.selectedMember.id]: null
                }
            };

        case actions.ADD_MEMBER_WITHOUT_SUBSCRIPTION :
            return {
                ...state,
                membersWithoutSubscription:{
                    ...state.membersWithoutSubscription,
                    [action.selectedMember.id]: action.selectedMember
                }
            };

        case actions.DELETE_MEMBER_WITHOUT_SUBSCRIPTION :
            return  {
                ...state,
                membersWithoutSubscription:{
                    ...state.membersWithoutSubscription,
                    [action.selectedMember.id]: null
                }
            };

        case  actions.CHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE :
            return  {
                ...state,
                isCurrentPageSelected : true
            };

        case  actions.UNCHECK_SELECTED_MEMBERS_FROM_CURRENT_PAGE :
            return  {
                ...state,
                isCurrentPageSelected : false
            };

        case  actions.CHECK_SELECT_ALL_MEMBERS :
            return  {
                ...state,
                selectAllMembers : {
                    isSelectAll: true,
                    currentURL : action.currentURL,
                    currentTab : action.currentTab
                }
            };

        case  actions.UNCHECK_SELECT_ALL_MEMBERS :
            return  {
                ...state,
                selectAllMembers : {
                    isSelectAll: false,
                    currentURL : ''
                }
            };

        case  actions.REMOVE_ALL_SELECTED_MEMBERS :
            return  {
                ...state,
                allMembers: {},
                activeMembers: {},
                expiredMembers: {},
                membersWithSubscription: {},
                membersWithoutSubscription: {},
                isCurrentPageSelected: false,
                selectAllMembers: { isSelectAll: false, currentURL : '', currentTab :''}
            };

        default :
            return state
    }
};

export default selectedMembers;